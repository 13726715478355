import React from 'react';
import styled from '@emotion/styled';
import Slider from 'react-slick';
// Import css files
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'gatsby';
import { BgImage } from 'gbimage-bridge';
import UseHomeCarousel from '../hooks/use-home-carousel';

const Slide = styled.div`
  background-size: cover !important;
  background-position: top;
  overflow: hidden;
  height: 400px;
`;

const StyledSlider = styled(Slider)`
  .carousel {
    overflow: hidden;
    height: 400px;
    width: 100%;
  }
  .usa-section-dark {
    background-color: rgba(138, 39, 64, 0.95);
    border-radius: 24px;
    min-height: 380px;
    text-align: center;
    p {
      color: white;
    }
  }
  .usa-hero__heading {
    color: #bca577;
  }

  .usa-hero__heading--alt {
    color: white;
  }
`;

export default function Carousel() {
  const homeCarousel = UseHomeCarousel();

  const settings = {
    dots: false,
    infinite: true,
    speed: 4500,
    slidesToShow: 1,
    slidesToScroll: 1,
    accessibility: true,
    adaptiveHeight: true,
    autoplay: true,
  };
  return (
    <StyledSlider {...settings} className='carousel'>
      {homeCarousel.map((carousel) => (
          <Slide key={carousel.id}>
            <BgImage image={carousel.image} loading="eager">
              <div className='grid-container'>
                <div className='usa-hero__callout usa-section-dark'>
                  <h2 className='usa-hero__heading'>
                    <span className='usa-hero__heading--alt'>ACHH</span> {carousel.title}
                  </h2>
                  <p>{carousel.description}</p>
                  {carousel.isExternal ? (
                    <a
                      className='usa-button'
                      href={carousel.link}
                      target='_blank'
                      rel='noreferrer'
                    >
                      {carousel.buttonText ? carousel.buttonText : 'Learn More'}
                    </a>
                  ) : (
                    <Link to={carousel.link} className='usa-button'>
                      {carousel.buttonText ? carousel.buttonText : 'Learn More'}
                    </Link>
                  )}
                </div>
              </div>
            </BgImage>
          </Slide>
        ))}
    </StyledSlider>
  );
}
