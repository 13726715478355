import * as React from 'react';
import styled from '@emotion/styled';
import Layout from '../../components/layout';
import Seo from '../../components/seo';

const AnchorButton = styled.button`
  a {
    color: white;
    text-decoration: none;
  }
`;

const EventRequest = () => {
  return (
    <Layout withIdentifier={true}>
      <Seo title='Museum Event Request' />
      <div className='grid-container usa-prose'>
        <h2>Event Request Information</h2>
        <div>
          <p>
            Thank you for your interest in booking an event with the AMEDD Museum. As part
            of our mission to promote AMEDD History and Heritage, the AMEDD Museum allows
            the reservation of locations on site for training and education sessions, as
            well as Army heritage events.
            <em>
              <strong>
                Please note that the AMEDD Museum can only provide the venue for these
                events. Museum staff are not trained Audio-Visual technicians or Protocol
                specialists, and we are unable to provide full event support.
              </strong>
            </em>
            As a public venue, we must remain open to visitors during standard business
            hours, and the safety and long-term preservation of our artifacts remains a
            key priority for our staff. We thank you for your understanding.
          </p>
          <p>
            All events are processed in the order in which they are received, making the
            AMEDD Museum a first-come, first-serve facility. Please note that AMEDD Museum
            staff are not authorized to provide information about an event to individuals
            not directly related in the planning and preparation for the specified event.
          </p>
          <p>
            In order to properly support our guests, we ask that every event submit the
            attached reservation form. The form can be returned to us via email or dropped
            off in person. There is also an online version that can be submitted on the
            AMEDD Museum website. Once received, we will contact you within two
            business-days regarding your reservation.
          </p>
        </div>
        <div>
          <h3>Building Usage Policies</h3>
          <p>
            In order to provide you the best support, while also ensuring the safety and
            long-term preservation of our historic artifacts, the AMEDD Museum adheres to
            the following policies regarding use of our facilities:
          </p>
          <ol>
            <li>
              We ask that all groups provide their own manpower for set up and clean up.
            </li>
            <li>
              To ensure we abide by officially-mandated building capacity limits, we
              require all events provide an expected number of guests to attend.
            </li>
            <li>
              Our hours for events are from 0800 to 1600 daily Monday-Friday. With U.S.
              Army Medical Center of Excellence approval, we may be able to arrange for
              events outside of those hours. If your event will fall outside normal hours,
              we request a minimum 14-day notice.
            </li>
            <li>
              Guided tours should allow for a minimum of 1 to 2-hours for tours. We
              additionally require a minimum 7-day notice to arrange for guides.
            </li>
            <li>
              The museum does not currently supply assets such as tablecloths, serving
              utensils, paper plates and cups, dishwashing detergent, etc. Please see
              limited asset list below in our Event Request Form.
            </li>
            <li>
              All events wishing to utilize Audio-Visual equipment are encouraged to
              arrange for IT support from their local unit. The AMEDD Museum is not
              responsible for internet and network errors, equipment malfunctions, missing
              equipment, or incompatible accessories.
            </li>
            <li>
              Only UL approved electrical heating elements may be used for heating food.{' '}
              <strong>
                For safety reasons, Sterno containers and other non-fire department
                approved heat sources are prohibited.
              </strong>
            </li>
            <li>
              Please note that <strong>alcohol is prohibited</strong> inside federal
              buildings unless prior approval is requested from JBSA- FSH, Post Commander.
              Please note that approval typically takes two weeks or more, and requests
              must be submitted through your command.
            </li>
            <li>
              We request that all groups ensure spaces utilized are cleaned and restored
              to their original state. Clean up includes, but is not limited to: wiping
              down tables, cleaning spills, returning utilized assets to their original
              location, and hauling event trash to the dumpster located in the parking lot
              across the street.
            </li>
            <li>
              Please note that failure to follow these policies may result in a request to
              return and rectify the discrepancy, and/or denied use of the museum’s
              facilities for future events by the requesting party and/or associated
              organization.
            </li>
          </ol>
        </div>
        <div className='text-center'>
          <p>
            Thank you for helping us preserve the Army Medical Department’s history and
            heritage. We look forward to serving you soon!
          </p>
          <p>
            <input
              className='usa-checkbox__input'
              type='checkbox'
              name='IhaveRead'
              id='IhaveRead_chkbx'
              required='required'
              onChange={() => {
                document.getElementById('showPDF').disabled =
                  !document.getElementById('IhaveRead_chkbx').checked;
              }}
            />
            <label className='usa-checkbox__label' htmlFor='IhaveRead_chkbx'>
              <strong>
                I have read the above instructions and completely understand them.
              </strong>
            </label>
          </p>
          <p>
            <AnchorButton className='usa-button' id='showPDF' disabled>
              <a
                href='https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/Event Registration Packet v3.0.pdf'
                target='_blank'
              >
                Download Request Form [PDF, 4 Pages]
              </a>
            </AnchorButton>
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default EventRequest;
