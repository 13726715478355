import React from 'react';
import { css } from '@emotion/react';
import { Link } from 'gatsby';
import { StaticImage, GatsbyImage } from 'gatsby-plugin-image';

function rankImage(rankString) {
  let rankImage = '';
  switch (rankString) {
    case 'Major':
      rankImage = (
        <StaticImage
          src='../images/O4-major.png'
          alt={rankString}
          height={45}
        ></StaticImage>
      );
      break;
    case 'Lieutenant Colonel':
      rankImage = (
        <StaticImage
          src='../images/O5-lieutenant-colonel.png'
          alt={rankString}
          height={45}
        ></StaticImage>
      );
      break;
    case 'Colonel':
      rankImage = (
        <StaticImage
          src='../images/O6-colonel.png'
          alt={rankString}
          height={40}
        ></StaticImage>
      );
      break;
    case 'Brigadier General':
      rankImage = (
        <StaticImage
          src='../images/O7-Brigadier-General.png'
          alt={rankString}
          height={50}
        ></StaticImage>
      );
      break;
    default:
      rankImage = '';
      break;
  }
  return rankImage;
}

const BioCard = ({ bio }) => {
  const cardName = `${bio.firstName} ${bio.middleName} ${bio.lastName}`;

  return (
    <li className='tablet:grid-col-4 usa-card'>
      <div className='usa-card__container'>
        <header className='usa-card__header'>
          <h2 className='margin-y-0'>{cardName}</h2>
          <div className='grid-row flex-justify'>
            <div className='grid-col'>
                <p className='text-gray-30 margin-y-0'>{bio.rank}</p>
                <p className='text-gray-30 margin-y-0'>{`${bio.yearBorn} - ${bio.yearDied}`}</p>
            </div>
            <div
              className='grid-col'
              css={css`
                text-align: right;
              `}
            >
              {rankImage(bio.rank)}
            </div>
          </div>
        </header>
        <div className='usa-card__body'>
          <p>{`${bio.snippet} ...`}</p>
        </div>
        <div className='usa-card__footer'>
          <Link to={bio.link} className='usa-button'>
            Learn More
          </Link>
        </div>
      </div>
    </li>
  );
};

export default BioCard;
