// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-history-amedd-corps-js": () => import("./../../../src/pages/history/amedd-corps.js" /* webpackChunkName: "component---src-pages-history-amedd-corps-js" */),
  "component---src-pages-history-biographies-js": () => import("./../../../src/pages/history/biographies.js" /* webpackChunkName: "component---src-pages-history-biographies-js" */),
  "component---src-pages-history-books-js": () => import("./../../../src/pages/history/books.js" /* webpackChunkName: "component---src-pages-history-books-js" */),
  "component---src-pages-history-historical-art-js": () => import("./../../../src/pages/history/historical-art.js" /* webpackChunkName: "component---src-pages-history-historical-art-js" */),
  "component---src-pages-history-memoirs-js": () => import("./../../../src/pages/history/memoirs.js" /* webpackChunkName: "component---src-pages-history-memoirs-js" */),
  "component---src-pages-history-surgeons-js": () => import("./../../../src/pages/history/surgeons.js" /* webpackChunkName: "component---src-pages-history-surgeons-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-museum-tools-event-request-information-js": () => import("./../../../src/pages/museum/tools-event-request-information.js" /* webpackChunkName: "component---src-pages-museum-tools-event-request-information-js" */),
  "component---src-template-museum-js": () => import("./../../../src/template/museum.js" /* webpackChunkName: "component---src-template-museum-js" */),
  "component---src-template-public-js": () => import("./../../../src/template/public.js" /* webpackChunkName: "component---src-template-public-js" */),
  "component---src-template-regiment-js": () => import("./../../../src/template/regiment.js" /* webpackChunkName: "component---src-template-regiment-js" */)
}

