
// newest to oldest
export const surgeonsData = [
  {
    firstName: 'Mary',
    middleName: 'K.',
    lastName: ' Izaguirre',
    dateBorn: '',
    dateDied: '',
    servedFrom: '25 Jan 2024',
    servedTo: 'Current',
    rank: 'Lieutenant General',
    link: '/history/surgeongenerals-m-izaguirre',
    imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/photo_izaguirre.jpg',
  },
  {
    firstName: 'Raymond',
    middleName: 'S.',
    lastName: 'Dingle',
    dateBorn: '',
    dateDied: '',
    servedFrom: '27 Sep 2019',
    servedTo: '25 Jan 2024',
    rank: 'Lieutenant General',
    link: '/history/surgeongenerals-r-dingle',
    imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/R_Scott_Dingle.jpg',
  },
  {
    firstName: 'Nadja',
    middleName: 'Y.',
    lastName: 'West',
    dateBorn: '',
    dateDied: '',
    servedFrom: '11 Dec 2015',
    servedTo: '16 Aug 2019',
    rank: 'Lieutenant General',
    link: '/history/surgeongenerals-n-west',
    imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/west.jpg',
  },
 {
    firstName: 'Patricia',
    middleName: 'D.',
    lastName: 'Horoho',
    dateBorn: '',
    dateDied: '',
    servedFrom: '5 Dec 2011',
    servedTo: '3 Dec 2015',
    rank: 'Lieutenant General',
    link: '/history/surgeongenerals-p-horoho',
    imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/horoho.jpg',
  },
 {
    firstName: 'Eric',
    middleName: 'B.',
    lastName: 'Schoomaker',
    dateBorn: '',
    dateDied: '',
    servedFrom: '12 Dec 2007',
    servedTo: '5 Dec 2011',
    rank: 'Lieutenant General',
    link: '/history/surgeongenerals-e-schoomaker',
    imageUrl:
      'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/schoomaker.jpg',
  },
  {
    firstName: 'Kevin',
    middleName: 'C.',
    lastName: 'Kiley',
    dateBorn: '',
    dateDied: '',
    servedFrom: '1 Oct 2004',
    servedTo: '11 March 2007',
    rank: 'Major General',
    link: '/history/surgeongenerals-k-kiley',
    imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/kiley.jpg',
  },
  {
    firstName: 'James',
    middleName: 'B.',
    lastName: 'Peake',
    dateBorn: '',
    dateDied: '',
    servedFrom: '22 Sept 2000',
    servedTo: '8 July 2004',
    rank: 'Lieutenant General',
    link: '/history/surgeongenerals-j-peake',
    imageUrl:
      'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/Peake_8576.jpg',
  },
  {
    firstName: 'Ronald',
    middleName: 'R.',
    lastName: 'Blanck',
    dateBorn: '',
    dateDied: '',
    servedFrom: '1 Oct 1996',
    servedTo: '30 June 2000',
    rank: 'Lieutenant General',
    link: '/history/surgeongenerals-r-blanck',
    imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/blanck.jpg',
  },
  {
    firstName: 'Alcide',
    middleName: 'M.',
    lastName: 'LaNoue',
    dateBorn: '02 Nov 1934',
    dateDied: '20 Feb 2021',
    servedFrom: '8 Sept 1992',
    servedTo: '1 Oct 1996',
    rank: 'Lieutenant General',
    link: '/history/surgeongenerals-a-lanoue',
    imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/LaNoue.jpg',
  },
  {
    firstName: 'Frank',
    middleName: 'F.',
    lastName: 'Ledford Jr.',
    dateBorn: '22 Apr 1934',
    dateDied: '15 May 2019',
    servedFrom: '1 June 1988',
    servedTo: '30 June 1992',
    rank: 'Lieutenant General',
    link: '/history/surgeongenerals-f-ledfordjr',
    imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/Ledford.jpg',
  },
  {
    firstName: 'Quinn',
    middleName: 'H.',
    lastName: 'Becker',
    dateBorn: '11 Jun 1930',
    dateDied: '13 Mar 2022',
    servedFrom: '1 Feb 1985',
    servedTo: '31 May 1988',
    rank: 'Lieutenant General',
    link: '/history/surgeongenerals-q-becker',
    imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/becker.jpg',
  },
  {
    firstName: 'Bernhard',
    middleName: 'T.',
    lastName: 'Mittemeyer',
    dateBorn: '30 Oct 1930',
    dateDied: '25 Jan 2023',
    servedFrom: '1 Oct 1981',
    servedTo: '31 Jan 1985',
    rank: 'Lieutenant General',
    link: '/history/surgeongenerals-b-mittemeyer',
    imageUrl:
      'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/Mittemeyer.jpg',
  },
  {
    firstName: 'Charles',
    middleName: 'C.',
    lastName: 'Pixley',
    dateBorn: '8 Aug 1923',
    dateDied: '31 Dec 2005',
    servedFrom: '1 Oct 1977',
    servedTo: '30 Sept 1981',
    rank: 'Lieutenant General',
    link: '/history/surgeongenerals-c-pixley',
    imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/pixley.jpg',
  },
  {
    firstName: 'Richard',
    middleName: 'R.',
    lastName: 'Taylor',
    dateBorn: '21 Nov 1922',
    dateDied: '8 Nov 1978',
    servedFrom: '1 Oct 1973',
    servedTo: '30 Sept 1977',
    rank: 'Lieutenant General',
    link: '/history/surgeongenerals-r-taylor',
    imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/taylor.jpg',
  },
 {
    firstName: 'Hal',
    middleName: 'B.',
    lastName: 'Jennings Jr.',
    dateBorn: '26 Aug 1915',
    dateDied: '12 Feb 2008',
    servedFrom: '1 Oct 1969',
    servedTo: '30 Sept 1973',
    rank: 'Lieutenant General',
    link: '/history/surgeongenerals-h- jenningsjr',
    imageUrl:'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/jennings.jpg',
  },
  {
    firstName: 'Leonard',
    middleName: 'D.',
    lastName: 'Heaton',
    dateBorn: '18 Nov 1902',
    dateDied: '10 Sept 1983',
    servedFrom: '1 June 1959',
    servedTo: '30 Sept 1969',
    rank: 'Lieutenant General',
    link: '/history/surgeongenerals-l-heaton',
    imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/heaton.jpg',
  },
  {
    firstName: 'Silas',
    middleName: 'B.',
    lastName: 'Hays',
    dateBorn: '18 Feb 1902',
    dateDied: '24 July 1964',
    servedFrom: '1 June 1955',
    servedTo: '31 May 1959',
    rank: 'Major General',
    link: '/history/surgeongenerals-s-hays',
    imageUrl:'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/hays.jpg',
  },
  {
    firstName: 'George',
    middleName: 'E.',
    lastName: 'Armstrong',
    dateBorn: '4 Aug 1900',
    dateDied: '9 June 1979',
    servedFrom: '1 June 1951',
    servedTo: '31 May 1955',
    rank: 'Major General',
    link: '/history/surgeongenerals-g-armstrong',
    imageUrl:'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/armstrong.jpg',
  },
  {
    firstName: 'Raymond',
    middleName: 'W.',
    lastName: 'Bliss',
    dateBorn: '12 May 1888',
    dateDied: '12 Dec 1965',
    servedFrom: '1 June 1947',
    servedTo: '31 May 1951',
    rank: 'Major General',
    link: '/history/surgeongenerals-r-bliss',
    imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/bliss.jpg',
  },
  {
    firstName: 'Norman',
    middleName: 'T.',
    lastName: 'Kirk',
    dateBorn: '3 Jan 1888',
    dateDied: '13 Aug 1960',
    servedFrom: '1 June 1943',
    servedTo: '31 May 1947',
    rank: 'Major General',
    link: '/history/surgeongenerals-n-kirk',
    imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/kirk.jpg',
  },
  {
    firstName: 'James',
    middleName: 'C.',
    lastName: 'Magee',
    dateBorn: '23 Jan 1883',
    dateDied: '15 Oct 1975',
    servedFrom: '1 June 1939',
    servedTo: '31 May 1943',
    rank: 'Major General',
    link: '/history/surgeongenerals-j-magee',
    imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/Magee.jpg',
  },
  {
    firstName: 'Charles',
    middleName: 'R.',
    lastName: 'Reynolds',
    dateBorn: '28 July 1877',
    dateDied: '2 Dec 1961',
    servedFrom: '1 June 1935',
    servedTo: '31 May 1939',
    rank: 'Major General',
    link: '/history/surgeongenerals-c-reynolds',
    imageUrl:'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/Reynolds.jpg',
  },
  {
    firstName: 'Robert',
    middleName: 'U.',
    lastName: 'Patterson',
    dateBorn: '16 July 1877',
    dateDied: '5 Dec 1950',
    servedFrom: '1 June 1931',
    servedTo: '31 May 1935',
    rank: 'Major General',
    link: '/history/surgeongenerals-r-patterson',
    imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/Patterson.jpg',
  },
  {
    firstName: 'Merritte',
    middleName: 'W.',
    lastName: 'Ireland',
    dateBorn: '31 May 1867',
    dateDied: '5 July 1952',
    servedFrom: '5 Oct 1918',
    servedTo: '31 May 1931',
    rank: 'Major General',
    link: '/history/surgeongenerals-m-ireland',
    imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/Ireland.jpg',
  },
  {
    firstName: 'William',
    middleName: 'C.',
    lastName: 'Gorgas',
    dateBorn: '3 Oct 1854',
    dateDied: '4 July 1920',
    servedFrom: '16 Jan 1914',
    servedTo: '3 Oct 1918',
    rank: 'Major General',
    link: '/history/surgeongenerals-w-gorgas',
    imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/Gorgas.jpg',
  },
  {
    firstName: 'George',
    middleName: 'H.',
    lastName: 'Torney',
    dateBorn: '1 June 1850',
    dateDied: '27 Dec 1913',
    servedFrom: '14 Jan 1909',
    servedTo: '27 Dec 1913',
    rank: 'Brigadier General',
    link: '/history/surgeongenerals-g-torney',
    imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/Torney.jpg',
  },
  {
    firstName: 'Robert',
    middleName: 'M.',
    lastName: "O'Reilly",
    dateBorn: '14 Jan 1845',
    dateDied: '3 Nov 1912',
    servedFrom: '7 Sept 1902',
    servedTo: '14 Jan 1909',
    rank: 'Brigadier General',
    link: '/history/surgeongenerals-r-oreilly',
    imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/OReilly.jpg',
  },
  {
    firstName: 'William',
    middleName: 'H.',
    lastName: 'Forwood',
    dateBorn: '7 Aug 1838',
    dateDied: '11 May 1915',
    servedFrom: '8 June',
    servedTo: '7 Sept 1902',
    rank: 'Brigadier General',
    link: '/history/surgeongenerals-w-forwood',
    imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/Forwood.jpg',
  },
  {
    firstName: 'George',
    middleName: 'M.',
    lastName: 'Sternberg',
    dateBorn: '8 June 1838',
    dateDied: '3 Nov 1915',
    servedFrom: '30 May 1893',
    servedTo: '8 June 1902',
    rank: 'Brigadier General',
    link: '/history/surgeongenerals-g-sternberg',
    imageUrl:'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/Sternberg.jpg',
  },
  {
    firstName: 'Charles',
    middleName: '',
    lastName: 'Sutherland',
    dateBorn: '29 May 1829',
    dateDied: '10 May 1895',
    servedFrom: '23 Dec 1890',
    servedTo: '29 May 1893',
    rank: 'Brigadier General',
    link: '/history/surgeongenerals-c-sutherland',
    imageUrl:
      'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/Sutherland.jpg',
  },
  {
    firstName: 'Jedediah',
    middleName: 'H.',
    lastName: 'Baxter',
    dateBorn: '11 March 1837',
    dateDied: '4 Dec 1890',
    servedFrom: '16 Aug',
    servedTo: '4 Dec 1890',
    rank: 'Brigadier General',
    link: '/history/surgeongenerals-j-baxter',
    imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/Baxter.jpg',
  },
  {
    firstName: 'John',
    middleName: '',
    lastName: 'Moore',
    dateBorn: '16 Aug 1826',
    dateDied: '18 March 1907',
    servedFrom: '18 Nov 1886',
    servedTo: '6 Aug 1890',
    rank: 'Brigadier General',
    link: '/history/surgeongenerals-j-moore',
    imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/Moore.jpg',
  },
  {
    firstName: 'Robert',
    middleName: '',
    lastName: 'Murray',
    dateBorn: '6 Aug 1823',
    dateDied: '1 Jan 1913',
    servedFrom: '28 Nov 1883',
    servedTo: '6 Aug 1886',
    rank: 'Brigadier General',
    link: '/history/surgeongenerals-r-murray',
    imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/Murray.jpg',
  },
  {
    firstName: 'Charles',
    middleName: 'H.',
    lastName: 'Crane',
    dateBorn: '19 July 1825',
    dateDied: '10 Oct 1883',
    servedFrom: '3 July 1882',
    servedTo: '10 Oct 1883',
    rank: 'Brigadier General',
    link: '/history/surgeongenerals-c-crane',
    imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/Crane.jpg',
  },
  {
    firstName: 'Joseph',
    middleName: 'K.',
    lastName: 'Barnes',
    dateBorn: '21 July 1817',
    dateDied: '5 April 1883',
    servedFrom: '22 Aug 1864',
    servedTo: '30 June 1882',
    rank: 'Brigadier General',
    link: '/history/surgeongenerals-j-barnes',
    imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/Barnes.jpg',
  },
  {
    firstName: 'William',
    middleName: 'A.',
    lastName: 'Hammond',
    dateBorn: '28 Aug 1828',
    dateDied: '5 Jan 1900',
    servedFrom: '25 April 1862',
    servedTo: '18 Aug 1864',
    rank: 'Brigadier General',
    link: '/history/surgeongenerals-w-hammond',
    imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/Hammond.jpg',
  },
  {
    firstName: 'Clement',
    middleName: 'A.',
    lastName: 'Finley',
    dateBorn: '11 May 1797',
    dateDied: '8 Sept 1879',
    servedFrom: '15 May 1861',
    servedTo: '14 Apr 1862',
    rank: 'Brigadier General',
    link: '/history/surgeongenerals-c-finley',
    imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/Finley.jpg',
  },
  {
    firstName: 'Thomas',
    middleName: '',
    lastName: 'Lawson',
    dateBorn: '29 Aug 1789',
    dateDied: '15 May 1861',
    servedFrom: '30 Nov 1836',
    servedTo: '15 May 1861',
    rank: '',
    link: '/history/surgeongenerals-t-lawson',
    imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/Lawson.jpg',
  },
  {
    firstName: 'Joseph',
    middleName: '',
    lastName: 'Lovell',
    dateBorn: '22 Dec 1788',
    dateDied: '17 Oct 1836',
    servedFrom: '18 Apr 1818',
    servedTo: '17 Oct 1836',
    rank: '',
    link: '/history/surgeongenerals-j-lovell',
    imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/lovell.jpg',
  },
  {
    firstName: 'James',
    middleName: '',
    lastName: 'Tilton',
    dateBorn: '1 June 1745',
    dateDied: '14 May 1822',
    servedFrom: '11 June 1813',
    servedTo: '15 June 1815',
    rank: '',
    link: '/history/surgeongenerals-j-tilton',
    imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/Tilton.jpg',
  },
  {
    firstName: 'James',
    middleName: '',
    lastName: 'Craik',
    dateBorn: '1730',
    dateDied: '6 Feb 1814',
    servedFrom: '19 July 1798',
    servedTo: '15 June 1800',
    rank: '',
    link: '/history/surgeongenerals-j-craik',
    imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/Craik.jpg',
  },
  {
    firstName: 'Richard',
    middleName: '',
    lastName: 'Allison',
    dateBorn: '1757',
    dateDied: '22 Mar 1816',
    servedFrom: '1792',
    servedTo: '1796',
    rank: '',
    link: '',
    imageUrl: '',
  },
  {
    firstName: 'John',
    middleName: '',
    lastName: 'Cochran',
    dateBorn: '1 Aug 1730',
    dateDied: '6 Apr 1807',
    servedFrom: '17 Jan 1781',
    servedTo: '3 Nov 1783',
    rank: '',
    link: '/history/surgeongenerals-j-cochran',
    imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/Cochran.jpg',
  },
  {
    firstName: 'William',
    middleName: '',
    lastName: 'Shippen Jr.',
    dateBorn: '21 Oct 1736',
    dateDied: '11 July 1808',
    servedFrom: '11 Apr 1777',
    servedTo: '3 Jan 1781',
    rank: '',
    link: '/history/surgeongenerals-w-shippen',
    imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/Shippen.jpg',
  },
  {
    firstName: 'John',
    middleName: '',
    lastName: 'Morgan',
    dateBorn: '10 June 1735',
    dateDied: '15 Oct 1789',
    servedFrom: '17 Oct 1775',
    servedTo: '9 Jan 1777',
    rank: '',
    link: '/history/surgeongenerals-j-morgan',
    imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/Morgan.jpg',
  },
  {
    firstName: 'Benjamin',
    middleName: '',
    lastName: 'Church',
    dateBorn: '24 Aug 1724',
    dateDied: '1776',
    servedFrom: '27 July 1775',
    servedTo: '17 Oct 1775',
    rank: '',
    link: '/history/surgeongenerals-b-church',
    imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/Church.jpg',
  },
];

//Oldest to newest
//export const surgeonsData = [
    //    {
    //        firstName: 'Benjamin',
    //        middleName: '',
    //        lastName: 'Church',
    //        dateBorn: '24 Aug 1724',
    //        dateDied: '1776',
    //        servedFrom: '27 July 1775',
    //        servedTo: '17 Oct 1775',
    //        rank: '',
    //        link: '/history/surgeongenerals-b-church',
    //        imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/Church.jpg',
    //    },
    //    {
    //        firstName: 'John',
    //        middleName: '',
    //        lastName: 'Morgan',
    //        dateBorn: '10 June 1735',
    //        dateDied: '15 Oct 1789',
    //        servedFrom: '17 Oct 1775',
    //        servedTo: '9 Jan 1777',
    //        rank: '',
    //        link: '/history/surgeongenerals-j-morgan',
    //        imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/Morgan.jpg',
    //    },
    //    {
    //        firstName: 'William',
    //        middleName: '',
    //        lastName: 'Shippen Jr.',
    //        dateBorn: '21 Oct 1736',
    //        dateDied: '11 July 1808',
    //        servedFrom: '11 Apr 1777',
    //        servedTo: '3 Jan 1781',
    //        rank: '',
    //        link: '/history/surgeongenerals-w-shippen',
    //        imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/Shippen.jpg',
    //    },
    //    {
    //        firstName: 'John',
    //        middleName: '',
    //        lastName: 'Cochran',
    //        dateBorn: '1 Aug 1730',
    //        dateDied: '6 Apr 1807',
    //        servedFrom: '17 Jan 1781',
    //        servedTo: '3 Nov 1783',
    //        rank: '',
    //        link: '/history/surgeongenerals-j-cochran',
    //        imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/Cochran.jpg',
    //    },
    //    {
    //        firstName: 'Richard',
    //        middleName: '',
    //        lastName: 'Allison',
    //        dateBorn: '1757',
    //        dateDied: '22 Mar 1816',
    //        servedFrom: '1792',
    //        servedTo: '1796',
    //        rank: '',
    //        link: '',
    //        imageUrl: '',
    //    },
    //    {
    //        firstName: 'James',
    //        middleName: '',
    //        lastName: 'Craik',
    //        dateBorn: '1730',
    //        dateDied: '6 Feb 1814',
    //        servedFrom: '19 July 1798',
    //        servedTo: '15 June 1800',
    //        rank: '',
    //        link: '/history/surgeongenerals-j-craik',
    //        imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/Craik.jpg',
    //    },
    //    {
    //        firstName: 'James',
    //        middleName: '',
    //        lastName: 'Tilton',
    //        dateBorn: '1 June 1745',
    //        dateDied: '14 May 1822',
    //        servedFrom: '11 June 1813',
    //        servedTo: '15 June 1815',
    //        rank: '',
    //        link: '/history/surgeongenerals-j-tilton',
    //        imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/Tilton.jpg',
    //    },
    //    {
    //        firstName: 'Joseph',
    //        middleName: '',
    //        lastName: 'Lovell',
    //        dateBorn: '22 Dec 1788',
    //        dateDied: '17 Oct 1836',
    //        servedFrom: '18 Apr 1818',
    //        servedTo: '17 Oct 1836',
    //        rank: '',
    //        link: '/history/surgeongenerals-j-lovell',
    //        imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/lovell.jpg',
    //    },
    //    {
    //        firstName: 'Thomas',
    //        middleName: '',
    //        lastName: 'Lawson',
    //        dateBorn: '29 Aug 1789',
    //        dateDied: '15 May 1861',
    //        servedFrom: '30 Nov 1836',
    //        servedTo: '15 May 1861',
    //        rank: '',
    //        link: '/history/surgeongenerals-t-lawson',
    //        imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/Lawson.jpg',
    //    },
    //    {
    //        firstName: 'Clement',
    //        middleName: 'A.',
    //        lastName: 'Finley',
    //        dateBorn: '11 May 1797',
    //        dateDied: '8 Sept 1879',
    //        servedFrom: '15 May 1861',
    //        servedTo: '14 Apr 1862',
    //        rank: 'Brigadier General',
    //        link: '/history/surgeongenerals-c-finley',
    //        imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/Finley.jpg',
    //    },
    //    {
    //        firstName: 'William',
    //        middleName: 'A.',
    //        lastName: 'Hammond',
    //        dateBorn: '28 Aug 1828',
    //        dateDied: '5 Jan 1900',
    //        servedFrom: '25 April 1862',
    //        servedTo: '18 Aug 1864',
    //        rank: 'Brigadier General',
    //        link: '/history/surgeongenerals-w-hammond',
    //        imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/Hammond.jpg',
    //    },
    //    {
    //        firstName: 'Joseph',
    //        middleName: 'K.',
    //        lastName: 'Barnes',
    //        dateBorn: '21 July 1817',
    //        dateDied: '5 April 1883',
    //        servedFrom: '22 Aug 1864',
    //        servedTo: '30 June 1882',
    //        rank: 'Brigadier General',
    //        link: '/history/surgeongenerals-j-barnes',
    //        imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/Barnes.jpg',
    //    },
    //    {
    //        firstName: 'Charles',
    //        middleName: 'H.',
    //        lastName: 'Crane',
    //        dateBorn: '19 July 1825',
    //        dateDied: '10 Oct 1883',
    //        servedFrom: '3 July 1882',
    //        servedTo: '10 Oct 1883',
    //        rank: 'Brigadier General',
    //        link: '/history/surgeongenerals-c-crane',
    //        imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/Crane.jpg',
    //    },
    //    {
    //        firstName: 'Robert',
    //        middleName: '',
    //        lastName: 'Murray',
    //        dateBorn: '6 Aug 1823',
    //        dateDied: '1 Jan 1913',
    //        servedFrom: '28 Nov 1883',
    //        servedTo: '6 Aug 1886',
    //        rank: 'Brigadier General',
    //        link: '/history/surgeongenerals-r-murray',
    //        imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/Murray.jpg',
    //    },
    //    {
    //        firstName: 'John',
    //        middleName: '',
    //        lastName: 'Moore',
    //        dateBorn: '16 Aug 1826',
    //        dateDied: '18 March 1907',
    //        servedFrom: '18 Nov 1886',
    //        servedTo: '6 Aug 1890',
    //        rank: 'Brigadier General',
    //        link: '/history/surgeongenerals-j-moore',
    //        imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/Moore.jpg',
    //    },
    //    {
    //        firstName: 'Jedediah',
    //        middleName: 'H.',
    //        lastName: 'Baxter',
    //        dateBorn: '11 March 1837',
    //        dateDied: '4 Dec 1890',
    //        servedFrom: '16 Aug',
    //        servedTo: '4 Dec 1890',
    //        rank: 'Brigadier General',
    //        link: '/history/surgeongenerals-j-baxter',
    //        imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/Baxter.jpg',
    //    },
    //    {
    //        firstName: 'Charles',
    //        middleName: '',
    //        lastName: 'Sutherland',
    //        dateBorn: '29 May 1829',
    //        dateDied: '10 May 1895',
    //        servedFrom: '23 Dec 1890',
    //        servedTo: '29 May 1893',
    //        rank: 'Brigadier General',
    //        link: '/history/surgeongenerals-c-sutherland',
    //        imageUrl:
    //            'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/Sutherland.jpg',
    //    },
    //    {
    //        firstName: 'George',
    //        middleName: 'M.',
    //        lastName: 'Sternberg',
    //        dateBorn: '8 June 1838',
    //        dateDied: '3 Nov 1915',
    //        servedFrom: '30 May 1893',
    //        servedTo: '8 June 1902',
    //        rank: 'Brigadier General',
    //        link: '/history/surgeongenerals-g-sternberg',
    //        imageUrl:
    //            'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/Sternberg.jpg',
    //    },
    //    {
    //        firstName: 'William',
    //        middleName: 'H.',
    //        lastName: 'Forwood',
    //        dateBorn: '7 Aug 1838',
    //        dateDied: '11 May 1915',
    //        servedFrom: '8 June',
    //        servedTo: '7 Sept 1902',
    //        rank: 'Brigadier General',
    //        link: '/history/surgeongenerals-w-forwood',
    //        imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/Forwood.jpg',
    //    },
    //    {
    //        firstName: 'Robert',
    //        middleName: 'M.',
    //        lastName: "O'Reilly",
    //        dateBorn: '14 Jan 1845',
    //        dateDied: '3 Nov 1912',
    //        servedFrom: '7 Sept 1902',
    //        servedTo: '14 Jan 1909',
    //        rank: 'Brigadier General',
    //        link: '/history/surgeongenerals-r-oreilly',
    //        imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/OReilly.jpg',
    //    },
    //    {
    //        firstName: 'George',
    //        middleName: 'H.',
    //        lastName: 'Torney',
    //        dateBorn: '1 June 1850',
    //        dateDied: '27 Dec 1913',
    //        servedFrom: '14 Jan 1909',
    //        servedTo: '27 Dec 1913',
    //        rank: 'Brigadier General',
    //        link: '/history/surgeongenerals-g-torney',
    //        imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/Torney.jpg',
    //    },
    //    {
    //        firstName: 'William',
    //        middleName: 'C.',
    //        lastName: 'Gorgas',
    //        dateBorn: '3 Oct 1854',
    //        dateDied: '4 July 1920',
    //        servedFrom: '16 Jan 1914',
    //        servedTo: '3 Oct 1918',
    //        rank: 'Major General',
    //        link: '/history/surgeongenerals-w-gorgas',
    //        imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/Gorgas.jpg',
    //    },
    //    {
    //        firstName: 'Merritte',
    //        middleName: 'W.',
    //        lastName: 'Ireland',
    //        dateBorn: '31 May 1867',
    //        dateDied: '5 July 1952',
    //        servedFrom: '5 Oct 1918',
    //        servedTo: '31 May 1931',
    //        rank: 'Major General',
    //        link: '/history/surgeongenerals-m-ireland',
    //        imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/Ireland.jpg',
    //    },
    //    {
    //        firstName: 'Robert',
    //        middleName: 'U.',
    //        lastName: 'Patterson',
    //        dateBorn: '16 July 1877',
    //        dateDied: '5 Dec 1950',
    //        servedFrom: '1 June 1931',
    //        servedTo: '31 May 1935',
    //        rank: 'Major General',
    //        link: '/history/surgeongenerals-r-patterson',
    //        imageUrl:
    //            'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/Patterson.jpg',
    //    },
    //    {
    //        firstName: 'Charles',
    //        middleName: 'R.',
    //        lastName: 'Reynolds',
    //        dateBorn: '28 July 1877',
    //        dateDied: '2 Dec 1961',
    //        servedFrom: '1 June 1935',
    //        servedTo: '31 May 1939',
    //        rank: 'Major General',
    //        link: '/history/surgeongenerals-c-reynolds',
    //        imageUrl:
    //            'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/Reynolds.jpg',
    //    },
    //    {
    //        firstName: 'James',
    //        middleName: 'C.',
    //        lastName: 'Magee',
    //        dateBorn: '23 Jan 1883',
    //        dateDied: '15 Oct 1975',
    //        servedFrom: '1 June 1939',
    //        servedTo: '31 May 1943',
    //        rank: 'Major General',
    //        link: '/history/surgeongenerals-j-magee',
    //        imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/Magee.jpg',
    //    },
    //    {
    //        firstName: 'Norman',
    //        middleName: 'T.',
    //        lastName: 'Kirk',
    //        dateBorn: '3 Jan 1888',
    //        dateDied: '13 Aug 1960',
    //        servedFrom: '1 June 1943',
    //        servedTo: '31 May 1947',
    //        rank: 'Major General',
    //        link: '/history/surgeongenerals-n-kirk',
    //        imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/kirk.jpg',
    //    },
    //    {
    //        firstName: 'Raymond',
    //        middleName: 'W.',
    //        lastName: 'Bliss',
    //        dateBorn: '12 May 1888',
    //        dateDied: '12 Dec 1965',
    //        servedFrom: '1 June 1947',
    //        servedTo: '31 May 1951',
    //        rank: 'Major General',
    //        link: '/history/surgeongenerals-r-bliss',
    //        imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/bliss.jpg',
    //    },
    //    {
    //        firstName: 'George',
    //        middleName: 'E.',
    //        lastName: 'Armstrong',
    //        dateBorn: '4 Aug 1900',
    //        dateDied: '9 June 1979',
    //        servedFrom: '1 June 1951',
    //        servedTo: '31 May 1955',
    //        rank: 'Major General',
    //        link: '/history/surgeongenerals-g-armstrong',
    //        imageUrl:
    //            'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/armstrong.jpg',
    //    },
    //    {
    //        firstName: 'Silas',
    //        middleName: 'B.',
    //        lastName: 'Hays',
    //        dateBorn: '18 Feb 1902',
    //        dateDied: '24 July 1964',
    //        servedFrom: '1 June 1955',
    //        servedTo: '31 May 1959',
    //        rank: 'Major General',
    //        link: '/history/surgeongenerals-s-hays',
    //        imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/hays.jpg',
    //    },
    //    {
    //        firstName: 'Leonard',
    //        middleName: 'D.',
    //        lastName: 'Heaton',
    //        dateBorn: '18 Nov 1902',
    //        dateDied: '10 Sept 1983',
    //        servedFrom: '1 June 1959',
    //        servedTo: '30 Sept 1969',
    //        rank: 'Lieutenant General',
    //        link: '/history/surgeongenerals-l-heaton',
    //        imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/heaton.jpg',
    //    },
    //    {
    //        firstName: 'Hal',
    //        middleName: 'B.',
    //        lastName: 'Jennings Jr.',
    //        dateBorn: '26 Aug 1915',
    //        dateDied: '12 Feb 2008',
    //        servedFrom: '1 Oct 1969',
    //        servedTo: '30 Sept 1973',
    //        rank: 'Lieutenant General',
    //        link: '/history/surgeongenerals-h- jenningsjr',
    //        imageUrl:
    //            'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/jennings.jpg',
    //    },
    //    {
    //        firstName: 'Richard',
    //        middleName: 'R.',
    //        lastName: 'Taylor',
    //        dateBorn: '21 Nov 1922',
    //        dateDied: '8 Nov 1978',
    //        servedFrom: '1 Oct 1973',
    //        servedTo: '30 Sept 1977',
    //        rank: 'Lieutenant General',
    //        link: '/history/surgeongenerals-r-taylor',
    //        imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/taylor.jpg',
    //    },
    //    {
    //        firstName: 'Charles',
    //        middleName: 'C.',
    //        lastName: 'Pixley',
    //        dateBorn: '8 Aug 1923',
    //        dateDied: '31 Dec 2005',
    //        servedFrom: '1 Oct 1977',
    //        servedTo: '30 Sept 1981',
    //        rank: 'Lieutenant General',
    //        link: '/history/surgeongenerals-c-pixley',
    //        imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/pixley.jpg',
    //    },
    //    {
    //        firstName: 'Bernhard',
    //        middleName: 'T.',
    //        lastName: 'Mittemeyer',
    //        dateBorn: '',
    //        dateDied: '',
    //        servedFrom: '1 Oct 1981',
    //        servedTo: '31 Jan 1985',
    //        rank: 'Lieutenant General',
    //        link: '/history/surgeongenerals-b-mittemeyer',
    //        imageUrl:
    //            'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/Mittemeyer.jpg',
    //    },
    //    {
    //        firstName: 'Quinn',
    //        middleName: 'H.',
    //        lastName: 'Becker',
    //        dateBorn: '',
    //        dateDied: '',
    //        servedFrom: '1 Feb 1985',
    //        servedTo: '31 May 1988',
    //        rank: 'Lieutenant General',
    //        link: '/history/surgeongenerals-q-becker',
    //        imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/becker.jpg',
    //    },
    //    {
    //        firstName: 'Frank',
    //        middleName: 'F.',
    //        lastName: 'Ledford Jr.',
    //        dateBorn: '22 Apr 1934',
    //        dateDied: '15 May 2019',
    //        servedFrom: '1 June 1988',
    //        servedTo: '30 June 1992',
    //        rank: 'Lieutenant General',
    //        link: '/history/surgeongenerals-f-ledfordjr',
    //        imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/Ledford.jpg',
    //    },
    //    {
    //        firstName: 'Alcide',
    //        middleName: 'M.',
    //        lastName: 'LaNoue',
    //        dateBorn: '02 Nov 1934',
    //        dateDied: '20 Feb 2021',
    //        servedFrom: '8 Sept 1992',
    //        servedTo: '1 Oct 1996',
    //        rank: 'Lieutenant General',
    //        link: '/history/surgeongenerals-a-lanoue',
    //        imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/LaNoue.jpg',
    //    },
    //    {
    //        firstName: 'Ronald',
    //        middleName: 'R.',
    //        lastName: 'Blanck',
    //        dateBorn: '',
    //        dateDied: '',
    //        servedFrom: '1 Oct 1996',
    //        servedTo: '30 June 2000',
    //        rank: 'Lieutenant General',
    //        link: '/history/surgeongenerals-r-blanck',
    //        imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/blanck.jpg',
    //    },
    //    {
    //        firstName: 'James',
    //        middleName: 'B.',
    //        lastName: 'Peake',
    //        dateBorn: '',
    //        dateDied: '',
    //        servedFrom: '22 Sept 2000',
    //        servedTo: '8 July 2004',
    //        rank: 'Lieutenant General',
    //        link: '/history/surgeongenerals-j-peake',
    //        imageUrl:
    //            'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/Peake_8576.jpg',
    //    },
    //    {
    //        firstName: 'Kevin',
    //        middleName: 'C.',
    //        lastName: 'Kiley',
    //        dateBorn: '',
    //        dateDied: '',
    //        servedFrom: '1 Oct 2004',
    //        servedTo: '11 March 2007',
    //        rank: 'Major General',
    //        link: '/history/surgeongenerals-k-kiley',
    //        imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/kiley.jpg',
    //    },
    //    {
    //        firstName: 'Eric',
    //        middleName: 'B.',
    //        lastName: 'Schoomaker',
    //        dateBorn: '',
    //        dateDied: '',
    //        servedFrom: '12 Dec 2007',
    //        servedTo: '5 Dec 2011',
    //        rank: 'Lieutenant General',
    //        link: '/history/surgeongenerals-e-schoomaker',
    //        imageUrl:
    //            'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/schoomaker.jpg',
    //    },
    //    {
    //        firstName: 'Patricia',
    //        middleName: 'D.',
    //        lastName: 'Horoho',
    //        dateBorn: '',
    //        dateDied: '',
    //        servedFrom: '5 Dec 2011',
    //        servedTo: '3 Dec 2015',
    //        rank: 'Lieutenant General',
    //        link: '/history/surgeongenerals-p-horoho',
    //        imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/horoho.jpg',
    //    },
    //    {
    //        firstName: 'Nadja',
    //        middleName: 'Y.',
    //        lastName: 'West',
    //        dateBorn: '',
    //        dateDied: '',
    //        servedFrom: '11 Dec 2015',
    //        servedTo: '16 Aug 2019',
    //        rank: 'Lieutenant General',
    //        link: '/history/surgeongenerals-n-west',
    //        imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/west.jpg',
    //    },
    //    {
    //        firstName: 'Raymond',
    //        middleName: 'S.',
    //        lastName: 'Dingle',
    //        dateBorn: '',
    //        dateDied: '',
    //        servedFrom: '27 Sep 2019',
    //        servedTo: '25 Jan 2024',
    //        rank: 'Lieutenant General',
    //        link: '/history/surgeongenerals-r-dingle',
    //        imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/R_Scott_Dingle.jpg',
    //    },
    //    {
    //        firstName: 'Mary',
    //        middleName: 'K.',
    //        lastName: ' Izaguirre',
    //        dateBorn: '',
    //        dateDied: '',
    //        servedFrom: '25 Jan 2024',
    //        servedTo: 'Current',
    //        rank: 'Lieutenant General',
    //        link: '/history/surgeongenerals-m-izaguirre',
    //        imageUrl: 'https://medcoeckapwstorprd01.blob.core.usgovcloudapi.net/achh/photo_izaguirre.jpg',
    //    },
    //];


