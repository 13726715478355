import * as React from 'react';
import Layout from '../../components/layout';
import Seo from '../../components/seo';
import UseArt from '../../hooks/use-art';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import ReturnButton from '../../components/common/returnButton';

const HistoricalArt = () => {
  const allArt = UseArt();
  return (
    <Layout withIdentifier={true}>
      {/* <pre>{JSON.stringify(allArt, null, 2)}</pre> */}
      <Seo title='HistoricalArt' />
      <ReturnButton />
      <div className='grid-container'>
        <h1>Historic Art Work</h1>
        <p className='measure-4'>
          Please note that these graphics are posted for use in bona-fide news and
          scholarly publications. Their presence here does not constitute permission for
          their use in commercial or partisan political publications, nor does their use
          by such publications constitute an endorsement of the publications or their
          sponsors by the Army Medical Department. For permission to use, please contact
          The Office of Medical History.
        </p>
        <ul class='usa-card-group'>
          {allArt &&
            allArt.map((art) => (
              <li className='tablet:grid-col-4 usa-card' key={art.id}>
                <div className='usa-card__container'>
                  <header className='usa-card__header'>
                    <h2 className='usa-card__heading'>{art.title}</h2>
                  </header>
                  <div className='usa-card__media'>
                    <div className='usa-card__img'>
                      <GatsbyImage image={art.image} alt={art.title} />
                    </div>
                  </div>

                  <div className='usa-card__footer'>
                    <Link to={art.link} className='usa-button'>
                      Learn More
                    </Link>
                  </div>
                </div>
              </li>
            ))}
        </ul>
      </div>
    </Layout>
  );
};

export default HistoricalArt;
