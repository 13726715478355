import * as React from 'react';
import { Link } from 'gatsby';
import Layout from '../../components/layout';
import Seo from '../../components/seo';
import BookFlag from '../../components/bookFlag';
import SideNav from '../../components/common/sideNav';
import ReturnButton from '../../components/common/returnButton';
import UseBookList from '../../hooks/use-book-list';
import { slugify } from '../../Utilities/Utility';

// Group by war like this
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/Reduce#grouping_objects_by_a_property
function groupBy(objectArray, property) {
  return objectArray.reduce(function (acc, obj) {
    let key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, []);
}

const Books = () => {
  const bookData = UseBookList();
  let groupedByCategory = groupBy(bookData, 'category');
  let sideNavLinks = [];

  Object.keys(groupedByCategory).map((category) => {
    sideNavLinks.push(category);
  });

  return (
    <Layout withIdentifier={true}>
      <Seo title='Books' />
      <ReturnButton />
      <div className='grid-container margin-top-5'>
        <h1>Books and Documents</h1>
        <p>
          Materials will be included here as they become available. Comments and
          suggestions should be sent to The Office of Medical History Webmaster. Some of
          the materials are in PDF format and you will need the Adobe Acrobat Reader to
          view them. If you need to download this program, it is available at the Adobe
          Acrobat Reader website.{' '}
          <a
            className='usa-link usa-link--external'
            target='_blank'
            href='https://get.adobe.com/reader/'
            rel='noreferrer'
          >
            https://get.adobe.com/reader/
          </a>
        </p>
        <p>
          Please note that in order to lessen download time the illustrations have not
          been included in the main text of the materials. Captions, notes, and links to
          the illustrations have been included in the appropriate places.
        </p>
        <div className='grid-row grid-gap'>
          {sideNavLinks ? (
            <div className='tablet:grid-col-3'>
              <SideNav sideNavLinks={sideNavLinks} />
            </div>
          ) : null}

          <div className='tablet:grid-col-9'>
            {Object.keys(groupedByCategory).map((category) => {
              return (
                <React.Fragment>
                  <h2 id={`${slugify(category)}`}>{category}</h2>
                  <ul className='usa-card-group'>
                    {groupedByCategory[category].map((book) => (
                      <BookFlag book={book} key={book.id} />
                    ))}
                  </ul>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Books;
