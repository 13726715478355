import { Link } from 'gatsby';
import * as React from 'react';
import Layout from '../../components/layout';
import Seo from '../../components/seo';
import { GatsbyImage } from 'gatsby-plugin-image';
import useMedicalMemoirs from '../../hooks/use-medical-memoirs';
import ReturnButton from '../../components/common/returnButton';

const Memoirs = () => {
  const memoirs = useMedicalMemoirs();

  return (
    <Layout withIdentifier={true}>
      <Seo title='Memoirs' />
      <ReturnButton />
      <div className='grid-container'>
        <h2>Medical Memoirs</h2>

        <ul className='usa-card-group'>
          {memoirs.map((memoir) => (
            <li className='tablet:grid-col-4 usa-card' key={memoir.id}>
              <div className='usa-card__container'>
                <header className='usa-card__header'>
                  <h2 className='usa-card__heading'>{memoir.title}</h2>
                </header>
                <div className='usa-card__media'>
                  <div className='usa-card__img'>
                    <GatsbyImage image={memoir.image} alt={memoir.title} />
                  </div>
                </div>
                <div className='usa-card__body'>
                  <p>{memoir.snippet}</p>
                </div>
                <div className='usa-card__footer'>
                  {memoir.isExternal ? (
                    <a className='usa-button' href={memoir.link}>
                      Learn more
                    </a>
                  ) : (
                    <Link className='usa-button' to={memoir.link}>
                      Learn more
                    </Link>
                  )}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </Layout>
  );
};

export default Memoirs;
