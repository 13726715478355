import React from 'react';
import { css } from '@emotion/react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

function rankImage(rankString) {
  let rankImage = '';
  switch (rankString) {
    case 'Major':
      rankImage = (
        <StaticImage
          src='../images/O4-major.png'
          alt={rankString}
          height='45'
        ></StaticImage>
      );
      break;
    case 'Lieutenant Colonel':
      rankImage = (
        <StaticImage
          src='../images/O5-lieutenant-colonel.png'
          alt={rankString}
          height='45'
        ></StaticImage>
      );
      break;
    case 'Colonel':
      rankImage = (
        <StaticImage
          src='../images/O6-colonel.png'
          alt={rankString}
          height='40'
        ></StaticImage>
      );
      break;
    case 'Brigadier General':
      rankImage = (
        <StaticImage
          src='../images/O7-Brigadier-General.png'
          alt={rankString}
          height='50'
        ></StaticImage>
      );
      break;
    case 'Major General':
      rankImage = (
        <StaticImage
          src='../images/O8-Major-General.png'
          alt={rankString}
          height='50'
        ></StaticImage>
      );
      break;
    case 'Lieutenant General':
      rankImage = (
        <StaticImage
          src='../images/O9-Lieutenant-General.png'
          alt={rankString}
          height='50'
        ></StaticImage>
      );
      break;
    case 'General':
      rankImage = (
        <StaticImage
          src='../images/O10-General.png'
          alt={rankString}
          height='50'
        ></StaticImage>
      );
      break;
    default:
      rankImage = '';
      break;
  }
  return rankImage;
}

const SurgeonCard = ({ bio }) => {
  const extLinkRegex = /^http/g;
  return (
    <li className='tablet:grid-col-4 usa-card'>
      <div className='usa-card__container'>
        <header className='usa-card__header'>
          <div className='grid-row flex-justify'>
            {bio.rank != '' ? (
              <>
                <div className='grid-col'>
                  <h2 className='margin-y-0'>{`${bio.firstName} ${bio.middleName} ${bio.lastName}`}</h2>
                </div>
                <div
                  className='grid-col'
                  css={css`
                    text-align: right;
                  `}
                >
                  {rankImage(bio.rank)}
                </div>
              </>
            ) : (
              <div className='grid-col'>
                <h2 className='margin-y-0'>{`${bio.firstName} ${bio.middleName} ${bio.lastName}`}</h2>
              </div>
            )}
          </div>
        </header>
        <div className='usa-card__body'>
          {bio.dateBorn != '' && bio.dateDied != '' ? (
            <>
              <p className='margin-y-0'>{`Born: ${bio.dateBorn}`}</p>
              <p className='margin-y-0'>{`Died: ${bio.dateDied}`}</p>
            </>
          ) : (
            <></>
          )}
          <p className='text-bold margin-y-0'>Served as Surgeon General</p>
          <p className='margin-y-0'>{`${bio.servedFrom} - ${bio.servedTo}`}</p>
        </div>
        {bio.imageUrl != '' ? (
          <div className='usa-card__media'>
            <div className='usa-card__img'>
              <img
                src={bio.imageUrl}
                alt={`${bio.firstName} ${bio.middleName} ${bio.lastName}`}
              ></img>
            </div>
          </div>
        ) : (
          <></>
        )}
        {bio.link != '' ? (
          <div className='usa-card__footer'>
            {extLinkRegex.test(bio.link) ? (
              <a href={bio.link} target='_blank' className='usa-button'>
                Learn More
              </a>
            ) : (
              <Link to={bio.link} className='usa-button'>
                Learn More
              </Link>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    </li>
  );
};

export default SurgeonCard;
