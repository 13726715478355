import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

const Footer = ({ siteTitle }) => (
  <footer className='usa-footer'>
    <div className='grid-container usa-footer__return-to-top'>
      <Link to='#'>Return to top</Link>
    </div>

    <div className='usa-footer__secondary-section'>
      <div className='grid-container'>
        <div className='grid-row grid-gap'>
          <div className='usa-footer__logo grid-row mobile-lg:grid-col-6 mobile-lg:grid-gap-2'>
            <div className='mobile-lg:grid-col-auto'>
              <StaticImage
                className='usa-footer__logo-img'
                src='../../images/achh-logo.png'
                alt='logo'
                width={80}
              />
            </div>
            <div className='mobile-lg:grid-col-auto'>
              <p className='usa-footer__logo-heading'>{siteTitle}</p>
            </div>
          </div>
          <div className='usa-footer__contact-links mobile-lg:grid-col-6'>
            <div className='usa-footer__social-links grid-row grid-gap-1'>
              <div className='grid-col-auto'>
                <a
                  className='usa-social-link usa-social-link--facebook'
                  href='https://www.facebook.com/ArmyMedicineHistory/'
                  target='_blank'
                >
                  <span>Facebook</span>
                </a>
              </div>
              <div className='grid-col-auto'>
                <a
                  className='usa-social-link usa-social-link--twitter'
                  href='https://twitter.com/ArmyMedHistory'
                  target='_blank'
                >
                  <span>Twitter</span>
                </a>
              </div>
              <div className='grid-col-auto'>
                <a
                  className='usa-social-link usa-social-link--youtube'
                  href='https://www.youtube.com/user/ameddhistory'
                  target='_blank'
                >
                  <span>YouTube</span>
                </a>
              </div>
              <div className='grid-col-auto'>
                <a
                  className='usa-social-link usa-social-link--instagram'
                  href='https://www.instagram.com/armymedicinehistory/'
                  target='_blank'
                >
                  <span>Instagram</span>
                </a>
              </div>
              <div className='grid-col-auto'>
                <a
                  className='usa-social-link usa-social-link--flickr'
                  href='https://www.flickr.com/photos/amedd_history/sets/'
                  target='_blank'
                >
                  <span>Flickr</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
