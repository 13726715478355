import * as React from 'react';
import { Link } from 'gatsby';
import Layout from '../../components/layout';
import Seo from '../../components/seo';
import { surgeonsData } from '../../components/surgeons/surgeonsData';
import SurgeonCard from '../../components/surgeonCard';
import ReturnButton from '../../components/common/returnButton';

const Surgeons = () => {
  return (
    <Layout withIdentifier={true} isFullWidth={true}>
      <Seo title='The Surgeons General' />
      <ReturnButton />
      <div className='grid-container'>
        <h1>The Surgeons General of the U.S. Army and Their Predecessors*</h1>
        <ul className='usa-card-group'>
          {surgeonsData.map((bio) => {
            return <SurgeonCard bio={bio} key={bio.lastName} />;
          })}
        </ul>
        <p>
          *The permanent position of The Surgeon General was not created until April 1818
          along with the Medical Department as one of the three first permanent staff
          departments. For more information on the early Army Medical Department see{' '}
          <Link to='/history/book-rev-gillett1-default'>
            The Army Medical Department 1775 - 1818
          </Link>{' '}
          by Mary C. Gillett.
        </p>
      </div>
    </Layout>
  );
};
export default Surgeons;
