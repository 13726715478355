import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { parseDocsPageLinks, addAside, addAsideHtml } from '../Utilities/Utility';
import Content from '../components/common/content';
import SEO from '../components/seo';
import ReturnButton from '../components/common/returnButton';

//eslint-disable-next-line
function Museum({ data }) {
  const publicContent = data.achhMuseumContentJson;

  const docNames = data.allAchhDocumentNamesJson.nodes;

  const allContent = [
    ...data.allAchhRegimentContentJson.nodes,
    ...data.allAchhHistoryContentJson.nodes,
    ...data.allAchhMuseumContentJson.nodes,
  ];

  let newHtml = '';
  let arrHTags = [];

  newHtml = parseDocsPageLinks(
    publicContent.Content,
    docNames,
    publicContent.Portlet,
    allContent,
    data.allSitePage.nodes
  );

  // add the aside to the side of the content.
  arrHTags = addAside(newHtml, publicContent.Portlet.toLowerCase().trim());

  // Add the id to each header tag
  newHtml = addAsideHtml(newHtml, publicContent.Portlet.toLowerCase().trim());

  // aside for default pages
  let newSideNav = '';
  if (arrHTags.length > 0) {
    newSideNav = arrHTags.map((tag) => {
      const newTag = tag.replace(/(<([^>]+)>)/gi, '');

      if (tag === '') {
        return null;
      }
      return (
        <li>
          <AnchorLink
            href={`#${tag
              .replace(/ /g, '-')
              .replace(/\&nbsp;/g, '')
              .toLowerCase()}`}
          >
            {newTag.replace(/\w+/g, function (w) {
              return w[0].toUpperCase() + w.slice(1).toLowerCase();
            })}
          </AnchorLink>
        </li>
      );
    });
  }

  return (
    <Layout>
      <SEO title='Museum' />
      <ReturnButton />
      <div className='grid-container margin-top-5'>
        <div className='grid-row grid-gap'>
          {/* new Aside for generic pages */}
          {arrHTags.length > 0 ? (
            <div className='desktop:grid-col-3'>
              <aside>
                <nav>
                  <ul className='usa-sidenav'>{newSideNav}</ul>
                </nav>
              </aside>
            </div>
          ) : null}
          <div
            className={
              'usa-layout-docs__main usa-prose ' +
              (arrHTags.length > 0 ? 'desktop:grid-col-9' : 'desktop:grid-col-12')
            }
          >
            <Content html={newHtml} />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query OneMuseumViewTypeMessage($id: Int) {
    achhMuseumContentJson(Portlet_ID: { eq: $id }) {
      Authorized
      Content_ID
      Portlet
      Portlet_ID
      Content
    }

    allAchhDocumentNamesJson {
      nodes {
        docGuid
        document_name
      }
    }

    allAchhRegimentContentJson {
      nodes {
        Authorized
        Content_ID
        Portlet
        Portlet_ID
        id
      }
    }

    allAchhHistoryContentJson {
      nodes {
        Authorized
        Content_ID
        Portlet
        Portlet_ID
        id
      }
    }

    allAchhMuseumContentJson {
      nodes {
        Content_ID
        Portlet
        Portlet_ID
      }
    }
    allSitePage {
      nodes {
        path
      }
    }
  }
`;

export default Museum;
