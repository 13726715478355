import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

const Identifier = () => (
  <div className='usa-identifier'>
    <section
      className='usa-identifier__section usa-identifier__section--masthead'
      aria-label='Agency identifier'
    >
      <div className='usa-identifier__container'>
        <div className='usa-identifier__logos'>
          <a href='https://medcoe.army.mil' className='usa-identifier__logo'>
            <StaticImage
              className='usa-identifier__logo-img'
              src='../../images/medcoe-logo.svg'
              alt='Parent agency'
              layout='fixed'
              width={60}
              height={60}
            />
          </a>
        </div>
        <div className='usa-identifier__identity' aria-label='Agency description'>
          <p className='usa-identifier__identity-domain'>medcoe.army.mil</p>
          <p className='usa-identifier__identity-disclaimer'>
            An official website of the{' '}
            <a href='https://medcoe.army.mil'>U.S. Army Medical Center of Excellence</a>
          </p>
        </div>
      </div>
    </section>
    <nav
      className='usa-identifier__section usa-identifier__section--required-links'
      aria-label='Important links'
    >
      <div className='usa-identifier__container'>
        <ul className='usa-identifier__required-links-list'>
          <li className='usa-identifier__required-links-item'>
            <a href='https://medcoe.army.mil/about-us'>About MedCoE</a>
          </li>
          <li className='usa-identifier__required-links-item'>
            <Link
              to='/history/tools-privacy'
              className='usa-identifier__required-link usa-link'
            >
              Privacy & Security Notice
            </Link>
          </li>
          <li className='usa-identifier__required-links-item'>
            <Link
              to='/history/tools-external'
              className='usa-identifier__required-link usa-link'
            >
              External Links Disclaimer
            </Link>
          </li>
          <li className='usa-identifier__required-links-item'>
            <Link
              to='/history/tools-postingdisclaimer'
              className='usa-identifier__required-link usa-link'
            >
              Disclaimer: Web Site Medical Information Posting Restrictions
            </Link>
          </li>
          <li className='usa-identifier__required-links-item'>
            <a
              href='https://www.inscom.army.mil/isalute'
              className='usa-identifier__required-link usa-link usa-link--external'
            >
              iSALUTE
            </a>
          </li>
          <li className='usa-identifier__required-links-item'>
            <a
              href='http://www.section508.gov/'
              className='usa-identifier__required-link usa-link usa-link--external'
            >
              Accessibility/Section 508
            </a>
          </li>
          <li className='usa-identifier__required-links-item'>
            <Link
              href='/history/tools-webaccess'
              className='usa-identifier__required-link'
            >
              Web Accessibility
            </Link>
          </li>
          <li className='usa-identifier__required-links-item'>
            <a
              href='https://www.tradoc.army.mil/FOIA/'
              className='usa-identifier__required-link usa-link'
            >
              FOIA requests
            </a>
          </li>
          <li className='usa-identifier__required-links-item'>
            <a
              href='https://www.disa.mil/About/Legal-and-Regulatory/No-Fear-Act'
              className='usa-identifier__required-link usa-link'
            >
              No FEAR Act data
            </a>
          </li>
          <li className='usa-identifier__required-links-item'>
            <a
              href='https://medcoe.army.mil/ig'
              className='usa-identifier__required-link usa-link'
            >
              Office of the Inspector General
            </a>
          </li>
          <li className='usa-identifier__required-links-item'>
            <a
              href='mailto:usarmy.jbsa.medcom-ameddcs.other.request-for-information@mail.mil'
              className='usa-identifier__required-link usa-link'
            >
              Request for information
            </a>
          </li>
        </ul>
      </div>
    </nav>
    <section
      className='usa-identifier__section usa-identifier__section--usagov'
      aria-label='U.S. government information and services'
    >
      <div className='usa-identifier__container'>
        <div className='usa-identifier__usagov-description'>
          Looking for U.S. government information and services?
        </div>{' '}
        <a href='https://www.usa.gov/' className='usa-link'>
          Visit USA.gov
        </a>
      </div>
    </section>
  </div>
);

export default Identifier;
