import React from 'react';
import Layout from '../../components/layout';
import Seo from '../../components/seo';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import UseCorps from '../../hooks/use-corps';
import ReturnButton from '../../components/common/returnButton';

const AMEDDCorps = () => {
  const corpsData = UseCorps();
  return (
    <Layout withIdentifier={true}>
      <Seo title='AMEDD Corps History' />
      <ReturnButton />
      <div className='grid-container'>
        <h2>AMEDD Corps History</h2>
        <div className='tablet:grid-col-6'>
          <ul className='usa-collection'>
            {corpsData.map((corps) => (
              <li className='usa-collection__item'>
                <GatsbyImage
                  className='usa-collection__img'
                  image={corps.image}
                  alt='Alt text'
                />
                <div className='usa-collection__body'>
                  <h3 className='usa-collection__heading'>
                    <Link className='usa-link' to={corps.link}>
                      {corps.title}
                    </Link>
                  </h3>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Layout>
  );
};

export default AMEDDCorps;
