import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

const StyledHeroSection = styled.section`
  background-color: #e2dbcd;
  color: '#212121';
`;

const SearchHero = () => {
  // FSP@BTS: Adds search.gov key and snippet
  React.useEffect(() => {
    var usasearch_config = { siteHandle: 'achh' };
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = '//search.usa.gov/javascripts/remote.loader.js';
    document.getElementsByTagName('head')[0].appendChild(script);
  }, []);
  return (
    <StyledHeroSection className='padding-top-6 padding-bottom-6'>
      <div className='grid-container'>
        <h2>Search thousands of digital records</h2>

        <section aria-label='Big search component'>
          <form
            className='usa-search usa-search--big'
            role='search'
            id='search_form'
            action='https://search.usa.gov/search'
            acceptCharset='UTF-8'
            method='get'
            role='search'
          >
            {/* eslint-disable-next-line jsx-a11y/label-has-for */}
            <label className='usa-sr-only' htmlFor='search-field-en-big'>
              Search
            </label>
            <input
              className='usa-input usagov-search-autocomplete'
              type='text'
              name='query'
              id='search-field-en-big query'
              autoComplete='off'
              css={css`
                /* mobile */
                @media only screen and (max-width: 480px) {
                  margin-top: 0;
                  height: 2rem;
                }
                @media only screen and (min-width: 480px) {
                  /* tablet and above */
                  margin-top: 0;
                  font-size: 1.06rem;
                  height: 3rem;
                }
              `}
            />
            <button
              className='usa-button'
              type='submit'
              name='commit'
              value='Search'
              data-disable-with='Search'
            >
              <span className='usa-search__submit-text'>Search</span>
            </button>
            <input name='utf8' type='hidden' value='&#x2713;' />
            <input type='hidden' name='affiliate' id='affiliate' value='achh' />
          </form>
        </section>
      </div>
    </StyledHeroSection>
  );
};

export default SearchHero;
