import { graphql, useStaticQuery } from 'gatsby';

const UseCorps = () => {
  const data = useStaticQuery(graphql`
    query CorpsQuery {
      allCorpsYaml {
        nodes {
          id
          title
          link
          image {
            childImageSharp {
              gatsbyImageData(
                width: 200
                height: 200
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  `);

  return data.allCorpsYaml.nodes.map((corps) => ({
    id: corps.id,
    title: corps.title,
    link: corps.link,
    image: corps.image.childImageSharp.gatsbyImageData,
  }));
};

export default UseCorps;
