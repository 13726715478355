import * as React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Hero from '../components/common/hero';
import SearchHero from '../components/searchHero';
import CategoryGrid from '../components/categoryGrid';
import Carousel from '../components/carousel';
import { Link } from 'gatsby';

const IndexPage = () => (
  <Layout withIdentifier={true}>
    <Seo title='Home' />
    <Carousel />
    <SearchHero />
    <CategoryGrid />
  </Layout>
);

export default IndexPage;
