import * as React from 'react';
import { css } from '@emotion/react';
import { Link } from 'gatsby';

const ReturnButton = () => {
  return (
    <>
      <Link
        className='usa-button position-fixed pin-top z-top'
        to='#'
        css={css`
          display: none;
          @media screen and (max-width: 640px) {
            display: inline-block;
          }
        `}
      >
        Return to top
      </Link>
      <Link
        className='usa-button usa-button--outline position-fixed pin-right z-top'
        to='#'
        css={css`
          @media screen and (max-width: 640px) {
            display: none;
          }
        `}
      >
        Return to top
      </Link>
    </>
  );
};

export default ReturnButton;
