import * as React from 'react';
import Layout from '../../components/layout';
import Seo from '../../components/seo';
import { bioData } from '../../components/biographies/biographyData';
import BioCard from '../../components/bioCard';
import ReturnButton from '../../components/common/returnButton';

const Biographies = () => {
  return (
    <Layout withIdentifier={true}>
      <Seo title='Biographies' />
      <ReturnButton />
      <div className='grid-container'>
        <h1>U.S. Army Medical Corps</h1>
        <h2>Biographies</h2>
      </div>
      <div className='grid-container'>
        <ul className='usa-card-group'>
          {bioData
            .sort(function (a, b) {
              var textA = a.lastName.toUpperCase() + a.firstName.toUpperCase();
              var textB = b.lastName.toUpperCase() + b.firstName.toUpperCase();
              return textA < textB ? -1 : textA > textB ? 1 : 0;
            })
            .map((bio) => {
              return <BioCard bio={bio} key={bio.lastName} />;
            })}
        </ul>
      </div>
    </Layout>
  );
};
export default Biographies;
