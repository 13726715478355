import React from 'react';
import { Link } from 'gatsby';
import { parseHtmlLocation } from '../Utilities/Utility';

const extLinkRegex = /^http/g;

const BookFlag = ({ book }) => (
  <li className='tablet:grid-col-12 usa-card'>
    <div className='usa-card__container'>
      <header className='usa-card__header'>
        <h2 className='usa-card__heading'>{book.title.replace(/\u00a0/g, ' ')}</h2>
        <h4>{book.author}</h4>
      </header>
      <div className='usa-card__body'>
        <p className='line-height-sans-2 text-base'>{book.snippet + '...'}</p>
      </div>
      <div className='usa-card__footer'>
        {extLinkRegex.test(book.link) ? (
          <a href={book.link} target='_blank' className='usa-button'>
            Read more
          </a>
        ) : (
          <Link to={`/${parseHtmlLocation(book.link)}`} className='usa-button'>
            Read more
          </Link>
        )}
      </div>
    </div>
  </li>
);

export default BookFlag;
