import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Banner from './common/banner';
import Header from './common/header';
import Footer from './common/footer';
import Identifier from './common/identifier';
import { Link } from 'gatsby';

const Layout = ({ children, withIdentifier = false }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const siteTitle = data.site.siteMetadata.title || `Title`;

  return (
    <React.Fragment>
      <Banner />      
      <Header siteTitle={siteTitle} />
      <Link className='usa-skipnav' href='#main-content'>
        Skip to main content
      </Link>

      <main id='main-content'>{children}</main>

      <Footer siteTitle={siteTitle} />
      {withIdentifier && <Identifier />}
    </React.Fragment>
  );
};

export default Layout;
