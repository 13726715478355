import { graphql, useStaticQuery } from 'gatsby';

const useMedicalMemoirs = () => {
  const data = useStaticQuery(graphql`
    query MedicalMemoirs {
      allMedicalMemoirsYaml {
        nodes {
          id
          title
          snippet
          link
          image {
            childImageSharp {
              gatsbyImageData(
                width: 400
                height: 500
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  `);

  return data.allMedicalMemoirsYaml.nodes.map((memoir) => ({
    id: memoir.id,
    title: memoir.title,
    snippet: memoir.snippet,
    link: memoir.link,
    image: memoir.image.childImageSharp.gatsbyImageData,
  }));
};

export default useMedicalMemoirs;
