import React from 'react';
import { Link } from 'gatsby';
import { css } from '@emotion/react';
import { StaticImage } from 'gatsby-plugin-image';

const Header = ({ siteTitle }) => {
  // FSP@BTS: Adds search.gov key and snippet
  React.useEffect(() => {
    var usasearch_config = { siteHandle: 'achh' };

    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = '//search.usa.gov/javascripts/remote.loader.js';
    document.getElementsByTagName('head')[0].appendChild(script);
  }, []);

  return (
    <React.Fragment>
      <header
        className='usa-header usa-header--extended'
        style={{ backgroundColor: '#e2dbcd' }}
      >
        <div className='usa-navbar'>
          <div className='usa-logo' id='extended-logo'>
            <em className='usa-logo__text'>
              <Link to='/' title='Home' aria-label='Home'>
                <StaticImage
                  src='../../images/achh-logo.png'
                  alt='ACHH logo'
                  width={80}
                  css={css`
                    vertical-align: bottom;
                    margin-right: 5px;
                    @media screen and (max-width: 951px) {
                      width: 30px;
                    }
                  `}
                />
                {siteTitle}
              </Link>
            </em>
          </div>
          <button className='usa-menu-btn'>Menu</button>
        </div>
        <nav aria-label='Primary navigation' className='usa-nav'>
          <div className='usa-nav__inner'>
            <button className='usa-nav__close'>
              <StaticImage
                src='../../../node_modules/uswds/dist/img/close.svg'
                alt='close'
                width={64}
              />
            </button>
            <Link to='/history/newsletters'>
              <StaticImage
                src='../../images/historian_newsletter.fw.png'
                alt='Historian Newsletter'
                width={200}
                css={css`
                  position: absolute;
                  top: 0;
                  right: 30px;
                  @media only screen and (max-width: 480px) {
                    display: none;
                  }
                `}
              />
            </Link>
            <ul className='usa-nav__primary usa-accordion'>
              <li className='usa-nav__primary-item'>
                <button
                  className='usa-accordion__button usa-nav__link'
                  aria-expanded='false'
                  aria-controls='extended-nav-section-four'
                >
                  <span>ACHH</span>
                </button>
                <ul id='extended-nav-section-four' className='usa-nav__submenu' hidden>
                  <li className='usa-nav__submenu-item'>
                    <Link to='/regiment/root-recruitment'>AMEDD Living History Company</Link>
                  </li>
                  <li className='usa-nav__submenu-item'>
                    <Link to='/history/historical-art'>Historical Art Work & Images</Link>
                  </li>
                  <li className='usa-nav__submenu-item'>
                    <Link to='/history/tools-faq'>Frequently Asked Questions</Link>
                  </li>
                  <li className='usa-nav__submenu-item'>
                    <Link to='/history/tools-contacts'>Contact Us</Link>
                  </li>
                </ul>
              </li>
              <li className='usa-nav__primary-item'>
                <button
                  className='usa-accordion__button usa-nav__link'
                  aria-expanded='false'
                  aria-controls='extended-nav-section-one'
                >
                  <span>History</span>
                </button>
                <ul id='extended-nav-section-one' className='usa-nav__submenu' hidden>
                  <li className='usa-nav__submenu-item'>
                    <Link to='/history/history'>
                      History of the Office of Medical History
                    </Link>
                  </li>
                  <li className='usa-nav__submenu-item'>
                    <Link to='/history/biographies'>AMEDD Biographies</Link>
                  </li>
                  <li className='usa-nav__submenu-item'>
                    <Link to='/history/amedd-corps'>AMEDD Corps History</Link>
                  </li>
                  <li className='usa-nav__submenu-item'>
                    <Link to='/history/books'>Books and Documents</Link>
                  </li>
                  <li className='usa-nav__submenu-item'>
                    <Link to='/history/memoirs'>Medical Memoirs</Link>
                  </li>
                  <li className='usa-nav__submenu-item'>
                    <Link to='/regiment/root-medal'>AMEDD Medal of Honor Recipients</Link>
                  </li>
                  <li className='usa-nav__submenu-item'>
                    <Link to='/history/organization'>Organizational Histories</Link>
                  </li>
                  <li className='usa-nav__submenu-item'>
                    <Link to='/history/surgeons'>The Surgeons General</Link>
                  </li>
                  <li className='usa-nav__submenu-item'>
                    <Link to='/history/reports'>
                      Annual Reports of the Surgeon General
                    </Link>
                  </li>
                  <li className='usa-nav__submenu-item'>
                    <Link to='/history/unitpages-unitpages'>
                      AMEDD Unit Patches and Lineage
                    </Link>
                  </li>
                  <li className='usa-nav__submenu-item'>
                    <Link to='/history/newsletters'>The AMEDD Historian Newsletter</Link>
                  </li>
                  <li className='usa-nav__submenu-item'>
                    <Link to='/history/tools-contacts'>Contact Us</Link>
                  </li>
                  <li className='usa-nav__submenu-item'>
                    <Link to='/history/tools-faq'>Frequently Asked Questions</Link>
                  </li>
                </ul>
              </li>
              <li className='usa-nav__primary-item'>
                <button
                  className='usa-accordion__button usa-nav__link'
                  aria-expanded='false'
                  aria-controls='extended-nav-section-two'
                >
                  <span>Regiment</span>
                </button>
                <ul id='extended-nav-section-two' className='usa-nav__submenu' hidden>
                  <li className='usa-nav__submenu-item'>
                    <Link to='/regiment/root-about'>About the AMEDD Regiment</Link>
                  </li>
                  <li className='usa-nav__submenu-item'>
                    <Link to='/regiment/root-recognition'>
                      AMEDD Regimental Recognition Program (DMOR, HMOR, FOR)
                    </Link>
                  </li>
                  <li className='usa-nav__submenu-item'>
                    <Link to='/regiment/root-uniform'>Historic Uniform Loan Program</Link>
                  </li>
                  <li className='usa-nav__submenu-item'>
                    <Link to='/regiment/root-traveling-flag'>
                      Traveling Regimental Flag Program
                    </Link>
                  </li>
                  <li className='usa-nav__submenu-item'>
                    <Link to='/regiment/root-items'>AMEDD Heraldic Items</Link>
                  </li>
                  <li className='usa-nav__submenu-item'>
                    <Link to='/regiment/root-valor'>AMEDD Awards for Valor</Link>
                  </li>
                  <li className='usa-nav__submenu-item'>
                    <Link to='/regiment/root-medal'>AMEDD Medal of Honor</Link>
                  </li>
                  <li className='usa-nav__submenu-item'>
                    <Link to='/regiment/root-certmerit'>Certificate of Merit</Link>
                  </li>
                  <li className='usa-nav__submenu-item'>
                    <Link to='/regiment/root-cross'>
                      AMEDD Distinguished Service Cross
                    </Link>
                  </li>
                  <li className='usa-nav__submenu-item'>
                    <Link to='/regiment/root-ameddsilverstar'>AMEDD Silver Star</Link>
                  </li>
                  <li className='usa-nav__submenu-item'>
                    <Link to='/regiment/root-dfc'>Distinguished Flying Cross</Link>
                  </li>
                  <li className='usa-nav__submenu-item'>
                    <Link to='/regiment/root-soldiers-medal'>Soldier's Medal</Link>
                  </li>
                  <li className='usa-nav__submenu-item'>
                    <Link to='/regiment/root-bronzev'>Bronze Star with "V" Device</Link>
                  </li>
                  <li className='usa-nav__submenu-item'>
                    <Link to='/regiment/airmedal-airmedal'>
                      Air Medal with "V" Device
                    </Link>
                  </li>
                  <li className='usa-nav__submenu-item'>
                    <Link to='/regiment/root-arcomv'>
                      Army Commendation Medal with "V" Device
                    </Link>
                  </li>
                  <li className='usa-nav__submenu-item'>
                    <Link to='/regiment/root-nco'>AMEDD NCO/Enlisted History</Link>
                  </li>
                  <li className='usa-nav__submenu-item'>
                    <Link to='/history/surgeons'>The Surgeons General</Link>
                  </li>
                  <li className='usa-nav__submenu-item'>
                    <Link to='/regiment/root-csm'>
                      Command Sergeants Major of HSC/MEDCOM
                    </Link>
                  </li>
                  <li className='usa-nav__submenu-item'>
                    <Link to='/regiment/root-music'>AMEDD Regimental Music</Link>
                  </li>
                  <li className='usa-nav__submenu-item'>
                    <Link to='/regiment/root-memorial'>Warrior Medic Memorial</Link>
                  </li>
                  <li className='usa-nav__submenu-item'>
                    <Link to='/regiment/root-prayer'>Combat Medic Prayer</Link>
                  </li>
                  <li className='usa-nav__submenu-item'>
                    <Link to='/regiment/root-posters'>AMEDD Posters</Link>
                  </li>
                  <li className='usa-nav__submenu-item'>
                    <Link to='/regiment/root-merit'>
                      Order of Military Medical Merit (O2M3)
                    </Link>
                  </li>
                  <li className='usa-nav__submenu-item'>
                    <Link to='/regiment/tools-contacts'>Contact Us</Link>
                  </li>
                </ul>
              </li>
              <li className='usa-nav__primary-item'>
                <button
                  className='usa-accordion__button usa-nav__link'
                  aria-expanded='false'
                  aria-controls='extended-nav-section-three'
                >
                  <span>Museum</span>
                </button>
                <ul id='extended-nav-section-three' className='usa-nav__submenu' hidden>
                  <li className='usa-nav__submenu-item'>
                    <Link to='/museum/root-about'>About the Museum</Link>
                  </li>
                  <li className='usa-nav__submenu-item'>
                    <Link to='/museum/root-visit'>Planning a Visit</Link>
                  </li>
                  <li className='usa-nav__submenu-item'>
                    <Link to='/museum/tools-event-request-information'>
                      Event Request Info
                    </Link>
                  </li>
                  <li className='usa-nav__submenu-item'>
                    <Link to='/museum/root-gallery'>Gallery</Link>
                  </li>
                  <li className='usa-nav__submenu-item'>
                    <Link to='/museum/virtualtour-virtualtour'>Virtual Tour</Link>
                  </li>
                </ul>
              </li>
            </ul>
            <div className='usa-nav__secondary'>
              <ul className='usa-nav__secondary-links'>
                <li className='usa-nav__secondary-item'>
                  <Link to='/history/tools-privacy'>Privacy policy</Link>
                </li>
                <li className='usa-nav__secondary-item'>
                  <Link to='/history/newsletters'>Newsletter</Link>
                </li>
              </ul>

              <form
                id='search_form'
                action='https://search.usa.gov/search'
                acceptCharset='UTF-8'
                method='get'
                className='usa-search usa-search--small'
                role='search'
              >
                <label htmlFor='query' className='usa-sr-only'>
                  Search small
                </label>
                <input
                  className='usagov-search-autocomplete'
                  type='text'
                  name='query'
                  id='query'
                  autoComplete='off'
                />
                <button
                  type='submit'
                  name='commit'
                  value='Search'
                  data-disable-with='Search'
                  className='usa-button'
                  css={css`
                    @media screen and (max-width: 30em) {
                      padding: 1rem !important;
                    }
                  `}
                >
                  <span className='usa-sr-only'>Search</span>
                </button>
                <input name='utf8' type='hidden' value='&#x2713;' />
                <input type='hidden' name='affiliate' id='affiliate' value='achh' />
              </form>
            </div>
          </div>
        </nav>
      </header>
    </React.Fragment>
  );
};

export default Header;
