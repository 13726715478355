export const bioData = [
  {
    lastName: 'Alden',
    firstName: 'Charles',
    middleName: 'Henry',
    yearBorn: '1836',
    yearDied: '1906',
    rank: 'Brigadier General',
    snippet:
      'Colonel Alden was a joiner of societies, medical, scientific, scholastic, and patriotic',
    link: '/history/biography-alden',
    imageUrl:
      'https://designsystem.digital.gov/img/introducing-uswds-2-0/built-to-grow--alt.jpg',
  },
  {
    lastName: 'Banister',
    firstName: 'John',
    middleName: 'Monro',
    yearBorn: '1854',
    yearDied: '1929',
    rank: 'Colonel',
    snippet:
      'From his entrance into the service he had interested himself in the practice of surgery and later had taken advantage of leaves of absence to acquire a knowledge of ophthalmology in the clinics of New York and Philadelphia',
    link: '/history/biography-banister',
    imageUrl:
      'https://designsystem.digital.gov/img/introducing-uswds-2-0/built-to-grow--alt.jpg',
  },
  {
    lastName: 'Billings',
    firstName: 'John',
    middleName: 'Shaw',
    yearBorn: '1838',
    yearDied: '1913',
    rank: 'Colonel',
    snippet:
      'Beyond question, the name of John Shaw Billings belongs with the most outstanding among the many gifted men who have held membership in the Army Medical Corps',
    link: '/history/biography-billings',
    imageUrl:
      'https://designsystem.digital.gov/img/introducing-uswds-2-0/built-to-grow--alt.jpg',
  },
  {
    lastName: 'Brooke',
    firstName: 'Roger',
    middleName: '',
    yearBorn: '1878',
    yearDied: '1940',
    rank: 'Brigadier General',
    snippet:
      'General Brooke interested himself in internal medicine and became well recognized as one of the foremost in that field that the Corps has produced',
    link: '/history/biography-brooke',
    imageUrl:
      'https://designsystem.digital.gov/img/introducing-uswds-2-0/built-to-grow--alt.jpg',
  },
  {
    lastName: 'Bushnell',
    firstName: 'George',
    middleName: 'Ensign',
    yearBorn: '1853',
    yearDied: '1924',
    rank: 'Colonel',
    snippet:
      'Colonel Bushnell was one of the earliest advocates of the theory that primary infection with tuberculosis occurred normally in infancy or childhood, that later manifestations of the disease came from reinfections from within and that exogenous infection of the adult was practically non-existent',
    link: '/history/biography-bushnell',
    imageUrl:
      'https://designsystem.digital.gov/img/introducing-uswds-2-0/built-to-grow--alt.jpg',
  },
  {
    lastName: 'La Garde',
    firstName: 'Louis',
    middleName: 'Anatole',
    yearBorn: '1849',
    yearDied: '1920',
    rank: 'Colonel',
    snippet:
      'Colonel La Garde’s first interest was the practice of medicine, with a special bent toward surgery and diseases of the eye',
    link: '/history/biography-lagarde',
    imageUrl:
      'https://designsystem.digital.gov/img/introducing-uswds-2-0/built-to-grow--alt.jpg',
  },
  {
    lastName: 'Tripler',
    firstName: 'Charles',
    middleName: 'Stuart',
    yearBorn: '1806',
    yearDied: '1866',
    rank: 'Brigadier General',
    snippet:
      'Charles Stuart Tripler (January 19, 1806-October 20, 1866), Brevet Brigadier General, U. S. Army, a distinguished member of the medical corps, was born on the "Bowery" in New York City',
    link: '/history/biography-tripler',
    imageUrl:
      'https://designsystem.digital.gov/img/introducing-uswds-2-0/built-to-grow--alt.jpg',
  },
  {
    lastName: 'Birmingham',
    firstName: 'Henry',
    middleName: 'Patrick',
    yearBorn: '1854',
    yearDied: '1932',
    rank: 'Brigadier General',
    snippet: 'General Birmingham was a fine type of that older service in which the medical officers were soldiers as well as surgeons',
    link: '/history/biography-birmingham',
    imageUrl:
      'https://designsystem.digital.gov/img/introducing-uswds-2-0/built-to-grow--alt.jpg',
  },
  {
    lastName: 'Bradley',
    firstName: 'Alfred',
    middleName: 'Eugene',
    yearBorn: '1864 ',
    yearDied: '1922',
    rank: 'Brigadier General',
    snippet: 'General Bradley’s first interest throughout his army career was the practice of medicine. He was a talented clinician and a skillful operator',
    link: '/history/biography-bradley',
    imageUrl:
      'https://designsystem.digital.gov/img/introducing-uswds-2-0/built-to-grow--alt.jpg',
  },
  {
    lastName: 'Darnall',
    firstName: 'Carl',
    middleName: 'Rodgers',
    yearBorn: '1867',
    yearDied: '1941',
    rank: 'Brigadier General',
    snippet: 'The record he made of his own life is extremely short, covering only a single page, but it is characteristic of him. He was a man of deeds and not of words',
    link: '/history/biography-darnall',
    imageUrl:
      'https://designsystem.digital.gov/img/introducing-uswds-2-0/built-to-grow--alt.jpg',
  },
  {
    lastName: 'Dewitt',
    firstName: 'Calvin',
    middleName: '',
    yearBorn: '1840',
    yearDied: '1908',
    rank: 'Brigadier General',
    snippet: 'Calvin DeWitt (May 26, 1840-September 2, 1908), Brigadier General, Medical Corps, U. S. Army, was born at Harrisburg, Pennsylvania',
    link: '/history/biography-dewitt',
    imageUrl:
      'https://designsystem.digital.gov/img/introducing-uswds-2-0/built-to-grow--alt.jpg',
  },
  {
    lastName: 'Girard',
    firstName: 'Alfred',
    middleName: 'Conrad',
    yearBorn: '1841',
    yearDied: '1914',
    rank: 'Brigadier General',
    snippet: 'The annual reports of The Surgeon General carry numerous case records which testify to Colonel Girard`s understanding of surgical pathology and to his skill as an operator',
    link: '/history/biography-girard',
    imageUrl:
      'https://designsystem.digital.gov/img/introducing-uswds-2-0/built-to-grow--alt.jpg',
  },
  {
    lastName: 'Glennan',
    firstName: 'James',
    middleName: 'D.',
    yearBorn: '1862',
    yearDied: '1927',
    rank: 'Brigadier General',
    snippet: 'General Glennan had much to do with the orderly expansion of the Army Medical Center and the beautiful landscaping of the grounds is especially to his credit',
    link: '/history/biography-glennan',
    imageUrl:
      'https://designsystem.digital.gov/img/introducing-uswds-2-0/built-to-grow--alt.jpg',
  },
  {
    lastName: 'Greenleaf',
    firstName: 'Charles',
    middleName: 'R.',
    yearBorn: '1838',
    yearDied: '1911',
    rank: 'Brigadier General',
    snippet: 'General Greenleaf`s military career began and terminated with the strenuous activities and pressing responsibilities of war',
    link: '/history/biography-greenleaf',
    imageUrl:
      'https://designsystem.digital.gov/img/introducing-uswds-2-0/built-to-grow--alt.jpg',
  },
  {
    lastName: 'Halloran',
    firstName: 'Paul',
    middleName: 'Stacy',
    yearBorn: '1874',
    yearDied: '1931',
    rank: 'Colonel',
    snippet: 'Colonel Halloran lived out his span of life as a bachelor, though none was more fitted for a family life',
    link: '/history/biography-halloran',
    imageUrl:
      'https://designsystem.digital.gov/img/introducing-uswds-2-0/built-to-grow--alt.jpg',
  },
  {
    lastName: 'Havard',
    firstName: 'Valery',
    middleName: '',
    yearBorn: '1846',
    yearDied: '1927',
    rank: 'Colonel',
    snippet: 'Colonel Havard was one of the most scholarly of the many educated men who have graced the corps, with a literary style that will warrant study and emulation',
    link: '/history/biography-havard',
    imageUrl:
      'https://designsystem.digital.gov/img/introducing-uswds-2-0/built-to-grow--alt.jpg',
  },
  {
    lastName: 'Hoff',
    firstName: 'Alexander',
    middleName: 'Henry',
    yearBorn: '1822',
    yearDied: '1876',
    rank: 'Colonel',
    snippet: 'He was the director of the fleet of floating hospitals on the Mississippi river and the originator of the modern hospital ship',
    link: '/history/biography-hoff',
    imageUrl:
      'https://designsystem.digital.gov/img/introducing-uswds-2-0/built-to-grow--alt.jpg',
  },
  {
    lastName: 'Irwin',
    firstName: 'John',
    middleName: 'Dowling',
    yearBorn: '1830',
    yearDied: '1917',
    rank: 'Brigadier General',
    snippet: 'Despite the title of “the fighting doctor” frequently bestowed upon General Irwin he was always interested primarily in his professional work',
    link: '/history/biography-irwin',
    imageUrl:
      'https://designsystem.digital.gov/img/introducing-uswds-2-0/built-to-grow--alt.jpg',
  },
  {
    lastName: 'Mann',
    firstName: 'James',
    middleName: '',
    yearBorn: '1759',
    yearDied: '1832',
    rank: 'Surgeon',
    snippet:
      'James Mann (July 23, 1759—November 7, 1832) Surgeon, U. S. Army, was born in Wrentham, Norfolk County, Massachusetts. No record of his parents or ancestry has come do to us.',
    link: '/history/biography-mann',
    imageUrl:
      'https://designsystem.digital.gov/img/introducing-uswds-2-0/built-to-grow--alt.jpg',
  },
  {
    lastName: 'McCaw',
    firstName: 'Walter',
    middleName: 'D.',
    yearBorn: '1863',
    yearDied: '1939',
    rank: 'Brigadier General',
    snippet: 'General McCaw was the fifth of a continuous line of Virginia physicians',
    link: '/history/biography-mccaw',
    imageUrl:
      'https://designsystem.digital.gov/img/introducing-uswds-2-0/built-to-grow--alt.jpg',
  },
  {
    lastName: 'Mower',
    firstName: 'Thomas',
    middleName: 'Gardiner',
    yearBorn: '1790',
    yearDied: '1853',
    rank: 'Major',
    snippet: 'Mower through the last thirty years of his life exercised an influence over the medical department practically coequal with the chiefs of the corps',
    link: '/history/biography-mower',
    imageUrl:
      'https://designsystem.digital.gov/img/introducing-uswds-2-0/built-to-grow--alt.jpg',
  },
  {
    lastName: 'Nichols',
    firstName: 'Henry',
    middleName: 'James',
    yearBorn: '1877',
    yearDied: '1927',
    rank: 'Lieutenant Colonel',
    snippet: 'Colonel Nichols spent the twenty years of his army career in practically unbroken service in laboratories and sanitary work',
    link: '/history/biography-nichols',
    imageUrl:
      'https://designsystem.digital.gov/img/introducing-uswds-2-0/built-to-grow--alt.jpg',
  },
  {
    lastName: 'Satterlee',
    firstName: 'Richard',
    middleName: 'Sherwood',
    yearBorn: '1796',
    yearDied: '1880',
    rank: 'Brigadier General',
    snippet: 'He is credited with high professional skill and judgment and he had well recognized gifts in administration',
    link: '/history/biography-satterlee',
    imageUrl:
      'https://designsystem.digital.gov/img/introducing-uswds-2-0/built-to-grow--alt.jpg',
  },
  {
    lastName: 'Smart',
    firstName: 'Charles',
    middleName: '',
    yearBorn: '1841',
    yearDied: '1905',
    rank: 'Brigadier General',
    snippet: 'Charles Smart (September 18, 1841-April 23, 1905), Brigadier General, Medical Corps, U. S. Army, was born in the city of Aberdeen, Scotland',
    link: '/history/biography-smart',
    imageUrl:
      'https://designsystem.digital.gov/img/introducing-uswds-2-0/built-to-grow--alt.jpg',
  },
  {
    lastName: 'Stark',
    firstName: 'Alexander',
    middleName: 'N.',
    yearBorn: '1869',
    yearDied: '1926',
    rank: 'Colonel',
    snippet: 'While Colonel Stark`s later service marked him as an administrator, he was one of the conspicuous clinicians and surgeons of the Corps of his time',
    link: '/history/biography-stark',
    imageUrl:
      'https://designsystem.digital.gov/img/introducing-uswds-2-0/built-to-grow--alt.jpg',
  },
];
