import { graphql, useStaticQuery } from 'gatsby';

const UseHomeCarousel = () => {
  const data = useStaticQuery(graphql`
    query homeCarouselQuery {
      allHomeCarouselYaml {
        nodes {
          id
          title
          description
          isExternal
          isAttachment
          link
          buttonText
          image {
            childImageSharp {
              gatsbyImageData(
                width: 1440
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                quality: 80
              )
            }
          }
        }
      }
    }
  `);

  if (data.allHomeCarouselYaml.nodes instanceof Array) {
    return data.allHomeCarouselYaml.nodes.map((carousel) => ({
      id: carousel.id,
      title: carousel.title,
      link: carousel.link,
      isExternal: carousel.isExternal,
      isAttachment: carousel.isAttachment,
      description: carousel.description,
      buttonText: carousel.buttonText,
      image: carousel.image.childImageSharp.gatsbyImageData,
    }));
  }
};

export default UseHomeCarousel;
