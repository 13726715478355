import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

const StyledHeroSection = styled.section`
  background-color: #e2dbcd;
  color: '#212121';
`;

const CollectionCard = () => (
  <div className='grid-row'>
    <div className='tablet:grid-col-6'>
      <div className='grid-row grid-gap margin-bottom-2'>
        <div className='tablet:grid-col-3'>
          <StaticImage
            src='../images/r-circle.png'
            alt='red circle'
            placeholder='blurred'
          />
        </div>
        <div className='tablet:grid-col-9'>
          <h2 className='usa-card__heading'>
            AMEDD Center of History and Heritage (ACHH)
          </h2>
          <p className='margin-top-0'>
            ACHH consists of a History and Archives branch and the AMEDD Regiment.
            Although separate, the AMEDD Museum is a partner in the presentation and
            preservation of AMEDD History. ACHH collects, preserves, and interprets the
            history of the AMEDD in order to educate and inform, serving as a multifaceted
            resource for the history of the AMEDD.
          </p>
        </div>
      </div>
      <div className='grid-row grid-gap margin-bottom-2'>
        <div className='tablet:grid-col-3'>
          <StaticImage
            src='../images/r-circle.png'
            alt='red circle'
            placeholder='blurred'
          />
        </div>
        <div className='tablet:grid-col-9'>
          <h2 className='usa-card__heading'>AMEDD Regiment</h2>
          <p className='margin-top-0'>
            The mission of the AMEDD Regiment is to enhance combat effectiveness by
            developing loyalty and commitment, fostering an extended sense of belonging,
            improving unit esprit, and institutionalizing the warfighting ethos. The
            Regiment provides services to perpetuate AMEDD history, heritage, traditions,
            valor and a sense of belonging.
          </p>
          <p>
            These include the{' '}
            <Link to='/regiment/root-recognition'>
              AMEDD Regimental Recognition Program
            </Link>{' '}
            (DMOR, HMOR, FOR), the{' '}
            <Link to='/regiment/root-uniform'>Historical Uniform Loan Program</Link>, and
            an online repository of awards for valor, the only one dedicated exclusively
            to Army Medical Department Soldiers.{' '}
            <Link to='/regiment/root-valor'>AMEDD Awards for Valor.</Link>
          </p>
        </div>
      </div>
    </div>
    <div className='tablet:grid-col-6'>
      <div className='grid-row grid-gap margin-bottom-2'>
        <div className='tablet:grid-col-3'>
          <StaticImage
            src='../images/r-circle.png'
            alt='red circle'
            placeholder='blurred'
          />
        </div>
        <div className='tablet:grid-col-9'>
          <h2 className='usa-card__heading'>History and Archives</h2>
          <p className='margin-top-0'>
            The historians and archivist of ACHH gather, preserve, interpret and present
            the history of the AMEDD. They also provide historical support through
            publications, outreach, and other media. The history of the Medical Center of
            Excellence (MEDCoE) is collected as a part of these efforts. Encompassing
            AMEDD documents and files, the research collection in the Archives consists of
            over 250,000 items.
          </p>
        </div>
      </div>
      <div className='grid-row grid-gap margin-bottom-2'>
        <div className='tablet:grid-col-3'>
          <StaticImage
            src='../images/r-circle.png'
            alt='red circle'
            placeholder='blurred'
          />
        </div>
        <div className='tablet:grid-col-9'>
          <h2 className='usa-card__heading'>AMEDD Museum</h2>
          <p className='margin-top-0'>
            The mission of the U.S. Army Medical Department Museum is to collect,
            preserve, exhibit, and interpret historically significant property related to
            the history of the Army Medical Department from 1775 to the present. As an
            educational institution, the museum will support training and education for
            military and civilian personnel.
          </p>
        </div>
      </div>
    </div>
  </div>
);

function CategoryGrid() {
  return (
    <StyledHeroSection className='padding-top-6 padding-bottom-6'>
      <div className='grid-container'>
        <CollectionCard />
      </div>
    </StyledHeroSection>
  );
}

export default CategoryGrid;
