import { graphql, useStaticQuery } from 'gatsby';

const UseBookList = () => {
  const data = useStaticQuery(graphql`
    query Books {
      allAchhBooksJson {
        nodes {
          id: ID
          category: Category
          title: BookName
          author: Author
          snippet: Excerpt
          link: StartPage
        }
      }
    }
  `);

  if (data.allAchhBooksJson.nodes instanceof Array) {
    return data.allAchhBooksJson.nodes.map((book) => ({
      id: book.id,
      category: book.category,
      title: book.title,
      author: book.author,
      snippet: book.snippet,
      link: book.link,
    }));
  }
};

export default UseBookList;
