import { graphql, useStaticQuery } from 'gatsby';

const UseArt = () => {
  const data = useStaticQuery(graphql`
    query AllArt {
      allArtDataYaml {
        nodes {
          id
          link
          title
          image {
            childImageSharp {
              gatsbyImageData(
                width: 360
                height: 200
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  `);

  return data.allArtDataYaml.nodes.map((art) => ({
    id: art.id,
    image: art.image.childImageSharp.gatsbyImageData,
    link: art.link,
    title: art.title,
  }));
};

export default UseArt;
