import React from 'react';
import { slugify } from '../../Utilities/Utility';

const SideNav = ({ sideNavLinks }) => {
  return sideNavLinks ? (
    <nav aria-label='Secondary navigation'>
      <ul className='usa-sidenav'>
        {sideNavLinks.map((sideNavLink) => {
          return (
            <li key={sideNavLink} className='usa-sidenav__item'>
              <a href={`#${slugify(sideNavLink)}`}>{sideNavLink}</a>
            </li>
          );
        })}
      </ul>
    </nav>
  ) : null;
};

export default SideNav;
