import React from 'react';
import styled from '@emotion/styled';

const StyledHeroSection = styled.section`
  background-color: ${(props) => props.bgColor || null};
  color: ${(props) => props.textColor || null};
`;

const Hero = ({ title, bgColor = '#fff', textColor = '#212121', children }) => (
  <StyledHeroSection
    className='padding-top-6 padding-bottom-6'
    bgColor={bgColor}
    textColor={textColor}
  >
    <div className='grid-container'>
      <div className='grid-row grid-gap-lg'>
        <div className='tablet:grid-col-5'>
          <h2 className='font-heading-xl margin-top-0'>{title}</h2>
        </div>
        <div className='tablet:grid-col-7'>{children}</div>
      </div>
    </div>
  </StyledHeroSection>
);

export default Hero;
